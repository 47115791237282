.footerMobile {
	width: 100%;
	height: 250px;
	margin-top: 20px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-top: 1px solid white;
	padding: 15px 30px;

	a {
		text-decoration: none;
		display: block;
	}

	.footerBgMobile {
		position: absolute;
		width: 100%;
		height: auto;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.left,
	.center,
	.right {
		flex: 1;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		position: relative;
		z-index: 5;
	}

	.left {
		align-items: flex-start;

		.link {
			font-size: 6px;
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			line-height: 6px;
			letter-spacing: 0.4000000059604645px;
			text-align: left;
			margin-bottom: 4px;
		}

		.copyrightWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			.dot {
				width: 13px;
				height: 13px;
				border: 0.25px solid white;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 3px;
				font-size: 8px;
				font-weight: 400;
				line-height: 10px;
				letter-spacing: 0.4000000059604645px;
				text-align: left;
			}

			span {
				font-size: 6px;
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				line-height: 6px;
				letter-spacing: 0.4000000059604645px;
				text-align: left;
			}
		}
	}

	.center {
		align-items: center;

		span {
			font-size: 24px;
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: -3px;
			text-align: left;
		}
	}

	.right {
		align-items: flex-end;

		.contact {
			font-size: 6px;
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			line-height: 6px;
			letter-spacing: 0em;
			text-align: left;
		}

		.mail {
			font-family: "Space Grotesk";
			font-size: 6px;
			font-weight: 500;
			line-height: 8px;
			letter-spacing: 0.4000000059604645px;
			text-align: left;
			margin-top: 1px;
			margin-bottom: 4px;
		}

		.socials {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: 7px;
				height: 7px;

				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}
}
