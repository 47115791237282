.notExistsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding-top: 120px;

    @media (max-width: 1440.5px) {
        padding-top: 8.333333333vw;
    }

    @media (max-width: 768.5px) {
        padding-top: 100px;
    }

    .pageContent {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        h1 {
            font-size: 2rem;
            max-width: 80vw;
        }

        a {
            font-size: 1.5rem;
            margin-top: 20px;
        }

    }
}