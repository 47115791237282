.lds-dual-ring {
    display: inline-block;
    width: 36px;
    height: 36px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 26.181818182px;
    height: 26.181818182px;
    margin: 0;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}