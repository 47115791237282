.landingWrapper {
	overflow: hidden;

	a {
		text-decoration: none;
	}

	.landingWrapperDesktop {
		width: 100vw;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 120px;
		// overflow: visible;
		// overflow-x: hidden;

		@media (max-width: 1440.5px) {
			padding-top: 8.33333333333vw;
			overflow-x: hidden;
			width: 100vw;
		}

		@media (max-width: 768.5px) {
			display: none;
		}

		.bgGradientTop {
			width: calc(100vw - 15px);
			height: 210px;
			// border: 1px solid red;
			background: linear-gradient(180deg, rgba(62, 254, 195, 0.4) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}

		.watermark {
			width: 100%;
			height: max-content;
			transition: 0.25s ease all;
			position: relative;
			z-index: 9;

			svg {
				max-width: 100%;
				height: auto;
			}
		}

		.sectionOne {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 30px;
			min-height: max-content;
			position: relative;
			z-index: 4;

			.left {
				position: relative;
				margin-right: 60px;
				width: max-content;
				max-width: max-content;
				height: max-content;
				z-index: 6;

				@media (max-width: 1440.5px) {
					margin-right: 4.16666666667vw;
				}

				.textBox {
					width: 600px;
					height: 230px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					pointer-events: none !important;
					// padding: 0 10px;

					@media (max-width: 1440.5px) {
						width: 41.666666667vw;
						height: 15.972222222vw;
					}

					img {
						width: 80%;
						pointer-events: none !important;
					}

					// h1 {
					//     font-size: 72px;
					//     font-family: "SATriumph";
					//     background: linear-gradient(90deg, #3DFEC4 0%, #3EFEC3 6.25%, #42FEBE 12.5%, #48FDB7 18.75%, #4FFCAE 25%, #59FCA3 31.25%, #64FB96 37.5%, #70FA88 43.75%, #7CF979 50%, #88F76A 56.25%, #94F65C 62.5%, #9FF54F 68.75%, #A9F544 75%, #B0F43B 81.25%, #B6F334 87.5%, #BAF32F 93.75%, #BBF32E 100%);
					//     color: black;
					//     background-clip: border-box;
					//     -webkit-background-clip: text;
					//     -webkit-text-stroke: 4px transparent;
					//     font-weight: 400;
					//     letter-spacing: .065em;
					//     padding-top: 20px;
					//     text-align: center;
					//     margin-top: 10px;

					//     @media (max-width: 1440.4px) {
					//         font-size: 5vw;
					//         padding-top: 1.388888889vw;
					//         margin-top: 0.694444444vw;
					//     }
					// }

					h3 {
						position: absolute;
						bottom: -60px;
						font-size: 24px;
						font-weight: 300;
						line-height: 29px;
						letter-spacing: 0em;
						text-align: center;
						width: 455px;
						height: 60px;
						color: #a5a5a5;

						@media (max-width: 1440.5px) {
							font-size: 1.666666667vw;
							line-height: 2.013888889vw;
							width: 31.25vw;
							height: 4.166666667vw;
							bottom: -4.166666667vw;
						}

						.underline {
							font-size: 24px;
							line-height: 24px;
							letter-spacing: 0em;
							text-align: center;
							text-decoration: underline;

							@media (max-width: 1440.5px) {
								font-size: 1.666666667vw;
								line-height: 1.666666667vw;
							}
						}
					}

					.borderLeft,
					.borderRight {
						position: absolute;
						top: 0;
						width: 30px;
						height: 100%;

						@media (max-width: 1440.5px) {
							width: 2.083333333vw;
						}
					}

					.borderLeft {
						left: 0;
						border-radius: 25px 0 0 25px;
						border-top: 1px solid white;
						border-left: 1px solid white;
						border-bottom: 1px solid white;

						@media (max-width: 1440.4px) {
							border-radius: 1.736111111vw 0 0 1.736111111vw;
						}
					}

					.borderRight {
						right: 0;
						border-radius: 0 25px 25px 0;
						border-top: 1px solid white;
						border-right: 1px solid white;
						border-bottom: 1px solid white;

						@media (max-width: 1440.4px) {
							border-radius: 0 1.736111111vw 1.736111111vw 0;
						}
					}
				}

				.buttonsContainer {
					display: flex;
					margin-top: 130px;
					justify-content: center;
					align-items: center;

					@media (max-width: 1440.5px) {
						margin-top: 9.027777778vw;
					}

					.btn1,
					.btn2 {
						width: 200px;
						height: 55px;
						background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
						border: 1px solid rgba(255, 255, 255, 0.8);
						box-sizing: border-box;
						border-radius: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-family: "Raleway", sans-serif;
						font-weight: 600;
						color: black;
						cursor: pointer;
						font-size: 16px;
						line-height: 16px;
						color: white;
						position: relative;

						span {
							position: absolute;
							transform: translate(-50%, -50%);
							top: 50%;
							left: 50%;
							width: max-content;
						}

						@media (max-width: 1440.5px) {
							width: 13.888888889vw;
							height: 3.819444444vw;
							border-radius: 2.083333333vw;
							font-size: 1.111111111vw;
							line-height: 1.111111111vw;
						}
					}

					.btn1 {
						margin-right: 31px;

						@media (max-width: 1440.5px) {
							margin-right: 2.152777778vw;
						}
					}
				}
			}

			.right {
				position: relative;
				width: 500px;
				z-index: 5;

				@media (max-width: 1440.5px) {
					max-width: 34.722222222vw;
				}

				.imgBox {
					position: absolute;
					transform: translate(-50%, -50%);
					top: 50%;
					left: 50%;
					width: 650px;
					height: max-content;

					@media (max-width: 1440.5px) {
						max-width: 34.722222222vw;
						// max-height: 48.134027778vw;
					}

					video {
						max-width: 100%;
						max-height: 100%;
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.lt1 {
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: center;
			margin-top: 280px;
			margin-left: auto;
			margin-right: auto;
			width: 900px;
			text-transform: uppercase;
			text-align: center;

			@media (max-width: 1440.5px) {
				width: 60.555555556vw;
				margin-top: 19.444444444vw;
				font-size: 1.666666667vw;
				line-height: 1.666666667vw;
			}
		}

		.lt2 {
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: center;
			margin-top: 25px;
			margin-left: auto;
			margin-right: auto;
			width: 900px;
			text-transform: uppercase;
			text-align: center;

			@media (max-width: 1440.5px) {
				width: 60.555555556vw;
				margin-top: 1.736111111vw;
				font-size: 1.666666667vw;
				line-height: 1.666666667vw;
			}
		}

		.sectionTwo {
			width: max-content;
			height: 450px;
			display: flex;
			margin-top: 385px;
			margin-left: auto;
			margin-right: auto;
			position: relative;

			@media (max-width: 1440.5px) {
				height: 31.25vw;
				margin-top: 26.736111111vw;
			}

			.interestsBelt {
				position: absolute;
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				min-width: 50vh;
				height: 50px;
				z-index: 1;
				display: flex;
				font-size: 24px;
				font-weight: 300;
				line-height: 31px;
				letter-spacing: 0em;
				text-align: left;
				overflow-x: hidden;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 3;

				span {
					margin-right: 40px;
					width: max-content;
				}

				* {
					font-family: "Space Grotesk" !important;
				}

				@media (max-width: 1440.5px) {
					height: 3.472222222vw;
					font-size: 1.666666667vw;
					line-height: 2.152777778vw;
				}
			}

			.bgLayer {
				position: absolute;
				transform: translate(-63%, -50%);
				top: 50%;
				left: 50%;
				z-index: 2;
				pointer-events: none !important;

				img {
					width: 2039px;
					height: 2207px;
					pointer-events: none !important;

					@media (max-width: 1440.5px) {
						width: 141.597222222vw;
						height: 153.263888889vw;
					}
				}
			}

			.left {
				width: 450px;
				margin-right: 25px;
				position: relative;
				z-index: 3;

				@media (max-width: 1440.5px) {
					width: 31.25vw;
					margin-right: 1.736111111vw;
				}

				.imgBox {
					position: absolute;
					z-index: 99999;
					transform: translate(-50%, -50%);
					top: 50%;
					left: 50%;
					width: max-content;
					height: max-content;
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 4;
					pointer-events: none !important;

					img {
						width: 450px;
						height: auto;
						position: relative;
						z-index: 9;
						pointer-events: none !important;

						@media (max-width: 1440.5px) {
							width: 31.25vw;
						}
					}
				}
			}

			.right {
				width: 525px;
				position: relative;
				z-index: 3;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: column;

				@media (max-width: 1440.5px) {
					width: 36.458333333vw;
				}

				.top {
					height: calc((100% / 2) - 25px);
					width: 100%;
					text-transform: uppercase;

					* {
						font-weight: 600;
					}

					@media (max-width: 1440.5px) {
						height: calc((100% / 2) - 1.736111111vw);
					}

					.line1 {
						font-size: 26px;
						line-height: 26px;

						@media (max-width: 1440.5px) {
							font-size: 1.805555556vw;
							line-height: 1.805555556vw;
						}
					}

					.line2 {
						font-size: 30px;
						line-height: 30px;
						background: linear-gradient(
							90deg,
							#3dfec4 0%,
							#3efec3 6.25%,
							#42febe 12.5%,
							#48fdb7 18.75%,
							#4ffcae 25%,
							#59fca3 31.25%,
							#64fb96 37.5%,
							#70fa88 43.75%,
							#7cf979 50%,
							#88f76a 56.25%,
							#94f65c 62.5%,
							#9ff54f 68.75%,
							#a9f544 75%,
							#b0f43b 81.25%,
							#b6f334 87.5%,
							#baf32f 93.75%,
							#bbf32e 100%
						);
						background-clip: text;
						color: transparent;
						margin-top: 3px;

						@media (max-width: 1440.5px) {
							font-size: 2.083333333vw;
							line-height: 2.083333333vw;
							margin-top: 0.208333333vw;
						}
					}

					.line3 {
						font-size: 40px;
						line-height: 40px;

						@media (max-width: 1440.5px) {
							font-size: 2.777777778vw;
							line-height: 2.777777778vw;
						}
					}
				}

				.bottom {
					height: calc((100% / 2) - 25px);
					width: 100%;
					position: relative;
					display: flex;
					justify-content: flex-end;
					align-items: flex-start;
					flex-direction: column;

					@media (max-width: 1440.5px) {
						height: calc((100% / 2) - 1.736111111vw);
					}

					.imgBox {
						width: 20px;
						height: 100%;
						display: flex;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						@media (max-width: 1440.5px) {
							width: 1.388888889vw;
							height: 14.583333333vw;
						}

						svg {
							min-width: 100%;
							min-height: 100%;
							max-width: 100%;
							max-height: 100%;
						}
					}

					h3 {
						font-size: 36px;
						line-height: 36px;
						font-weight: 400;
						letter-spacing: 0em;
						margin-left: 56px;
						text-transform: capitalize;
						margin-bottom: 50px;

						@media (max-width: 1440.5px) {
							font-size: 2.5vw;
							line-height: 2.5vw;
							margin-left: 3.888888889vw;
							margin-bottom: 3.472222222vw;
						}

						.bold {
							font-size: 36px;
							line-height: 36px;
							letter-spacing: 0em;
							text-align: justified;

							@media (max-width: 1440.5px) {
								font-size: 2.5vw;
								line-height: 2.5vw;
							}
						}
					}

					a {
						width: 200px;
						height: 55px;
						background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
						border: 1px solid rgba(255, 255, 255, 0.8);
						box-sizing: border-box;
						border-radius: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-family: "Raleway", sans-serif;
						font-weight: 600;
						color: black;
						cursor: pointer;
						font-size: 16px;
						line-height: 16px;
						color: white;
						text-decoration: none;
						margin-left: 56px;
						position: absolute;
						bottom: 0;
						transform: translateY(50%);

						@media (max-width: 1440.5px) {
							width: 13.888888889vw;
							height: 3.819444444vw;
							border-radius: 2.083333333vw;
							font-size: 1.111111111vw;
							line-height: 1.111111111vw;
							margin-left: 3.888888889vw;
						}
					}
				}
			}
		}

		.sectionThree {
			width: 740px;
			height: 334px;
			margin-top: 130px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			z-index: 10;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			flex-direction: column;

			@media (max-width: 1440.5px) {
				width: 51.388888889vw;
				height: 23.194444444vw;
				margin-top: 9.027777778vw;
			}

			.borderLeft {
				width: 55px;
				height: 250px;
				border-top: 1px solid white;
				border-left: 1px solid white;
				border-bottom: 1px solid white;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				left: -55px;
				border-radius: 10px 0 0 10px;

				@media (max-width: 1440.5px) {
					width: 3.819444444vw;
					height: 17.361111111vw;
					left: -3.819444444vw;
					border-radius: 0.694444444vw 0 0 0.694444444vw;
				}
			}

			.borderRight {
				width: 55px;
				height: 250px;
				border-top: 1px solid white;
				border-right: 1px solid white;
				border-bottom: 1px solid white;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				right: -55px;
				border-radius: 0 10px 10px 0;

				@media (max-width: 1440.5px) {
					width: 3.819444444vw;
					height: 17.361111111vw;
					right: -3.819444444vw;
					border-radius: 0 0.694444444vw 0.694444444vw 0;
				}
			}

			.subtitle {
				width: 450px;
				font-size: 24px;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0em;
				text-align: right;
				color: #fff;

				@media (max-width: 1440.5px) {
					width: 31.25vw;
					font-size: 1.666666667vw;
					line-height: 2.222222222vw;
				}
			}

			img {
				max-width: 100%;
			}
		}

		.sectionFour {
			margin-top: 110px;
			width: 869px;
			height: 546px;
			margin-left: auto;
			margin-right: auto;
			pointer-events: none !important;

			@media (max-width: 1440.5px) {
				margin-top: 7.638888889vw;
				width: 60.347222222vw;
				height: 37.916666667vw;
			}

			img {
				max-width: 100%;
				max-height: 100%;
				pointer-events: none !important;
			}
		}

		.sectionFive {
			margin-top: 312px;
			position: relative;
			min-height: max-content;
			padding-top: 80px;

			@media (max-width: 1440.5px) {
				margin-top: 21.666666667vw;
				padding-top: 5.555555556vw;
			}

			.label {
				width: 100vw;
				height: 80px;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				font-size: 64px;
				line-height: 64px;
				letter-spacing: 0em;
				padding-left: 200px;

				@media (max-width: 1440.5px) {
					height: 5.555555556vw;
					font-size: 4.444444444vw;
					line-height: 4.444444444vw;
					padding-left: 13.888888889vw;
				}

				&2 {
					top: 523px;

					@media (max-width: 1440.5px) {
						top: 36.319444444vw;
					}
				}

				.innerWrapper {
					max-width: 1440px;
					width: 100%;
					height: 100%;
					margin-left: auto;
					margin-right: auto;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.line {
						width: 15px;
						height: 100%;
						background-color: white;
						margin-right: 35px;

						@media (max-width: 1440.5px) {
							width: 1.041666667vw;
							margin-right: 2.430555556vw;
						}
					}
				}
			}

			.contentBox1 {
				width: 1029px;
				height: 444px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: auto;
				margin-right: auto;

				@media (max-width: 1440.5px) {
					height: 30.833333333vw;
					width: 71.458333333vw;
				}

				.left {
					flex: 1;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					.listLabel {
						display: flex;
						flex-direction: column;
						font-size: 36px;
						line-height: 36px;
						letter-spacing: 0em;
						text-align: center;
						transform: rotate(-90deg);
						margin-right: -22px;

						@media (max-width: 1440.5px) {
							font-size: 2.5vw;
							line-height: 2.5vw;
							margin-right: -1.527777778vw;
						}

						svg {
							transform: rotate(-270deg);
							width: 34px;
							height: 25px;

							@media (max-width: 1440.5px) {
								width: 2.361111111vw;
								height: 1.736111111vw;
							}
						}
					}

					.list {
						.listItem {
							display: flex;
							align-items: center;

							&:not(:last-child) {
								margin-bottom: 22px;

								@media (max-width: 1440.5px) {
									margin-bottom: 1.527777778vw;
								}
							}

							.dot {
								width: 20px;
								height: 20px;
								background: linear-gradient(
									90deg,
									#3dfec4 0%,
									#3efec3 6.25%,
									#42febe 12.5%,
									#48fdb7 18.75%,
									#4ffcae 25%,
									#59fca3 31.25%,
									#64fb96 37.5%,
									#70fa88 43.75%,
									#7cf979 50%,
									#88f76a 56.25%,
									#94f65c 62.5%,
									#9ff54f 68.75%,
									#a9f544 75%,
									#b0f43b 81.25%,
									#b6f334 87.5%,
									#baf32f 93.75%,
									#bbf32e 100%
								);
								border-radius: 50%;
								margin-right: 17px;

								@media (max-width: 1440.5px) {
									width: 1.388888889vw;
									height: 1.388888889vw;
									margin-right: 1.180555556vw;
								}
							}

							span {
								font-family: "Space Grotesk";
								font-style: normal;
								font-weight: 400;
								font-size: 24px;
								line-height: 24px;

								@media (max-width: 1440.5px) {
									font-size: 1.666666667vw;
									line-height: 1.666666667vw;
								}
							}
						}
					}
				}

				.right {
					flex: 1;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 450px;
						height: auto;

						@media (max-width: 1440.5px) {
							width: 31.25vw;
							height: auto;
						}
					}
				}
			}

			.contentBox2 {
				width: 1107px;
				height: 1305px;
				margin-top: 80px;
				padding-top: 40px;
				margin-left: auto;
				margin-right: auto;

				@media (max-width: 1440.5px) {
					height: 90.625vw;
					margin-top: 5.555555556vw;
					padding-top: 2.777777778vw;
					width: 76.875vw;
				}

				.top {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					height: max-content;

					@media (max-width: 1440.5px) {
						height: max-content;
					}

					.left {
						flex: 1;
						height: 82px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 111px;
						position: relative;

						@media (max-width: 1440.5px) {
							height: 5.694444444vw;
						}

						.boxLabel {
							width: max-content;
							height: max-content;
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							font-size: 40px;
							font-weight: 700;
							line-height: 40px;
							letter-spacing: 0em;
							transform: rotate(-90deg);
							margin: 0;
							padding: 0;

							@media (max-width: 1440.5px) {
								font-size: 2.777777778vw;
								line-height: 2.777777778vw;
							}
						}

						.box {
							width: max-content;
							height: max-content;
							display: flex;
							flex-direction: column;
							padding: 5px 20px;
							position: relative;

							@media (max-width: 1440.5px) {
								padding: 0.347222222vw 1.388888889vw;
							}

							.borderLeft,
							.borderRight {
								width: 20px;
								height: 100%;
								position: absolute;
								top: 0;
								border-top: 1px solid white;
								border-bottom: 1px solid white;

								@media (max-width: 1440.5px) {
									width: 1.388888889vw;
								}
							}

							.borderLeft {
								left: -10px;
								border-left: 1px solid white;
								border-radius: 6px 0 0 6px;

								@media (max-width: 1440.5px) {
									left: -0.694444444vw;
									border-radius: 0.416666667vw 0 0 0.416666667vw;
								}
							}

							.borderRight {
								right: -10px;
								border-right: 1px solid white;
								border-radius: 0 6px 6px 0;

								@media (max-width: 1440.5px) {
									right: -0.694444444vw;
									border-radius: 0 0.416666667vw 0.416666667vw 0;
								}
							}

							span {
								font-size: 24px;
								font-weight: 400;
								line-height: 36px;
								letter-spacing: 0em;
								text-align: left;

								@media (max-width: 1440.5px) {
									font-size: 1.666666667vw;
									line-height: 2.5vw;
								}
							}

							.underline {
								font-size: 30px;
								font-weight: 400;
								line-height: 36px;
								letter-spacing: 0em;
								text-align: left;
								text-decoration: underline;

								@media (max-width: 1440.5px) {
									font-size: 2.083333333vw;
									line-height: 2.5vw;
								}
							}
						}
					}

					.right {
						flex: 1;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							width: 414px;
							height: 391px;
							max-width: 100%;

							@media (max-width: 1440.5px) {
								width: 28.75vw;
								height: 27.152777778vw;
							}
						}
					}
				}

				.bottom {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					height: max-content;
					margin-top: 170px;

					@media (max-width: 1440.5px) {
						margin-top: 11.805555556vw;
					}

					.left {
						flex: 1;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 182px;
						margin-right: 92px;

						@media (max-width: 1440.5px) {
							margin-top: 16.545454545vw;
							margin-right: 6.388888889vw;
						}

						.boxLabel {
							width: max-content;
							height: max-content;
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							font-size: 40px;
							line-height: 40px;
							letter-spacing: 0em;
							transform: rotate(-90deg);
							margin: 0;
							padding: 0;
							margin-right: -20px;

							@media (max-width: 1440.5px) {
								font-size: 2.777777778vw;
								line-height: 2.777777778vw;
								margin-right: -1.388888889vw;
							}
						}

						.box {
							width: max-content;
							height: max-content;
							display: flex;
							flex-direction: column;
							padding: 20px 25px;
							position: relative;

							@media (max-width: 1440.5px) {
								padding: 1.388888889vw 1.736111111vw;
							}

							.borderLeft,
							.borderRight {
								width: 20px;
								height: 100%;
								position: absolute;
								top: 0;
								border-top: 1px solid white;
								border-bottom: 1px solid white;

								@media (max-width: 1440.5px) {
									width: 1.388888889vw;
								}
							}

							.borderLeft {
								left: -20px;
								border-left: 1px solid white;
								border-radius: 6px 0 0 6px;

								@media (max-width: 1440.5px) {
									left: -1.388888889vw;
									border-radius: 0.416666667vw 0 0 0.416666667vw;
								}
							}

							.borderRight {
								right: -20px;
								border-right: 1px solid white;
								border-radius: 0 6px 6px 0;

								@media (max-width: 1440.5px) {
									right: -1.388888889vw;
									border-radius: 0 0.416666667vw 0.416666667vw 0;
								}
							}

							span {
								font-size: 24px;
								font-weight: 400;
								line-height: 36px;
								letter-spacing: 0em;
								text-align: left;

								@media (max-width: 1440.5px) {
									font-size: 1.666666667vw;
									line-height: 2.5vw;
								}
							}

							.underline {
								font-size: 30px;
								font-weight: 400;
								line-height: 36px;
								letter-spacing: 0em;
								text-align: left;
								text-decoration: underline;

								@media (max-width: 1440.5px) {
									font-size: 2.083333333vw;
									line-height: 2.5vw;
								}
							}
						}
					}

					.right {
						flex: 1;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						svg {
							width: 596px;
							height: 750px;
							max-width: 100%;

							@media (max-width: 1440.5px) {
								width: 41.388888889vw;
								height: 52.083333333vw;
							}
						}
					}
				}
			}
		}

		.sectionSix {
			margin-top: 250px;
			margin-left: auto;
			margin-right: auto;
			width: max-content;
			height: max-content;
			display: flex;
			flex-direction: column;

			@media (max-width: 1440.5px) {
				margin-top: 17.361111111vw;
			}

			.row {
				height: 60px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: max-content;

				&:not(:last-child) {
					margin-bottom: 22px;

					@media (max-width: 1440.5px) {
						margin-bottom: 1.527777778vw;
					}
				}

				.box {
					width: max-content;
					height: max-content;
					display: flex;
					flex-direction: column;
					padding: 5px 50px;
					position: relative;
					width: 70px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 40px;

					span {
						font-size: 48px;
						font-weight: 400;
						line-height: 58px;
						letter-spacing: 0em;

						@media (max-width: 1440.5px) {
							font-size: 3.333333333vw;
							line-height: 4.027777778vw;
						}
					}

					@media (max-width: 1440.5px) {
						padding: 0.347222222vw 3.472222222vw;
						margin-right: 2.777777778vw;
					}

					.borderLeft,
					.borderRight {
						width: 10px;
						height: 100%;
						position: absolute;
						top: 0;
						border-top: 1px solid white;
						border-bottom: 1px solid white;

						@media (max-width: 1440.5px) {
							width: 0.694444444vw;
						}
					}

					.borderLeft {
						left: 0;
						border-left: 1px solid white;
						border-radius: 6px 0 0 6px;

						@media (max-width: 1440.5px) {
							border-radius: 0.416666667vw 0 0 0.416666667vw;
						}
					}

					.borderRight {
						right: 0;
						border-right: 1px solid white;
						border-radius: 0 6px 6px 0;

						@media (max-width: 1440.5px) {
							border-radius: 0 0.416666667vw 0.416666667vw 0;
						}
					}
				}

				.text {
					font-family: "Space Grotesk", sans-serif;
					font-size: 40px;
					font-weight: 300;
					line-height: 51px;
					letter-spacing: 0em;
					text-align: left;

					@media (max-width: 1440.5px) {
						font-size: 2.777777778vw;
						line-height: 3.541666667vw;
					}

					.bold {
						font-family: "Space Grotesk", sans-serif;
						font-size: 40px;
						font-weight: 600;
						line-height: 51px;
						letter-spacing: 0em;
						text-align: left;

						@media (max-width: 1440.5px) {
							font-size: 2.777777778vw;
							line-height: 3.541666667vw;
						}
					}
				}
			}
		}

		.sectionSeven {
			margin-top: 250px;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: 1440.5px) {
				margin-top: 17.361111111vw;
			}

			.row {
				height: 150px;
				width: 1100px;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				@media (max-width: 1440.5px) {
					height: 10.416666667vw;
					width: 76.388888889vw;
				}

				.lineTop {
					width: 80px;
					height: 16px;
					background-color: white;
					position: absolute;
					transform: translateY(-50%);
					top: 0;
					left: 0;

					@media (max-width: 1440.5px) {
						width: 5.555555556vw;
						height: 1.111111111vw;
					}
				}

				.lineBottom {
					width: 80px;
					height: 16px;
					background-color: white;
					position: absolute;
					transform: translateY(-50%);
					bottom: -16px;
					left: 0;

					@media (max-width: 1440.5px) {
						width: 5.555555556vw;
						height: 1.111111111vw;
						bottom: -1.111111111vw;
					}
				}

				.contentBox {
					width: 950px;
					height: 100%;
					border-top: 1px solid white;
					display: flex;
					align-items: flex-end;

					@media (max-width: 1440.5px) {
						width: 65.972222222vw;
					}

					&:last-child {
						border-bottom: 1px solid white;
					}

					.label {
						width: 470px;
						height: 100%;
						font-family: "Montserrat", sans-serif;
						font-size: 64px;
						font-weight: 600;
						line-height: 78px;
						letter-spacing: 0em;
						text-align: left;
						display: flex;
						align-items: center;

						@media (max-width: 1440.5px) {
							width: 32.638888889vw;
							font-size: 4.444444444vw;
							line-height: 5.416666667vw;
						}
					}

					.filler {
						flex: 1;
					}

					.dot {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						border: 1px solid white;
						margin-right: 50px;
						margin-bottom: 10px;

						@media (max-width: 1440.5px) {
							width: 2.083333333vw;
							height: 2.083333333vw;
							margin-right: 3.472222222vw;
							margin-bottom: 0.694444444vw;
						}
					}

					.value {
						width: 300px;
						height: 100%;
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						font-family: "Space Grotesk", sans-serif;
						font-size: 20px;
						font-weight: 300;
						line-height: 26px;
						letter-spacing: 0em;
						text-align: left;
						padding-top: 20px;

						@media (max-width: 1440.5px) {
							width: 20.833333333vw;
							font-size: 1.388888889vw;
							line-height: 1.805555556vw;
							padding-top: 1.388888889vw;
						}
					}
				}
			}
		}

		.sectionEight {
			height: max-content;
			margin-top: 275px;
			position: relative;

			@media (max-width: 1440.5px) {
				margin-top: 19.097222222vw;
			}

			.label {
				display: flex;
				width: 100%;
				height: 80px;
				position: relative;

				@media (max-width: 1440.5px) {
					height: 5.555555556vw;
				}

				.line {
					height: 100%;
					width: 15px;
					background-color: white;
					margin-left: 210px;
					margin-right: 25px;

					@media (max-width: 1440.5px) {
						width: 1.041666667vw;
						margin-left: 14.583333333vw;
						margin-right: 1.736111111vw;
					}

					&2 {
						position: absolute;
						height: 2px;
						width: 105vw;
						background-color: white;
						bottom: -2px;
						transform: translateX(-50%);
						left: 50%;

						@media (max-width: 1440.5px) {
							height: 0.138888889vw;
							bottom: -0.138888889vw;
						}
					}
				}

				span {
					font-size: 72px;
					line-height: 72px;
					letter-spacing: 0px;
					height: max-content;

					@media (max-width: 1440.5px) {
						font-size: 5vw;
						line-height: 5vw;
					}
				}
			}

			svg {
				width: 1440px;
				height: 1800px;
				position: relative;
				z-index: 20;

				@media (max-width: 1440.5px) {
					width: 100vw;
					height: 125vw;
				}
			}

			.bottomLine {
				height: 2px;
				width: 50vw;
				position: absolute;
				bottom: 4px;
				left: 70vw;
				transform: translateX(-50%);
				background-color: white;
				z-index: 20;

				@media (max-width: 1440.5px) {
					display: none;
				}
			}
		}

		.sectionNine {
			position: relative;
			margin-top: 162px;
			height: max-content;
			padding-top: 130px;

			@media (max-width: 1440.5px) {
				margin-top: 11.25vw;
				padding-top: 9.027777778vw;
			}

			.bgGradient2 {
				position: absolute;
				top: -1500px;
				left: -1200px;
				width: 277vw;
				height: 277vw;

				@media (max-width: 1440.5px) {
					top: -104.166666667vw;
					left: -83.333333333vw;
				}

				img {
					max-width: 100%;
					width: 100%;
					height: auto;
				}
			}

			.label {
				width: 100vw;
				height: 80px;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				font-size: 64px;
				line-height: 64px;
				letter-spacing: 0em;
				position: relative;
				z-index: 20;

				@media (max-width: 1440.5px) {
					height: 5.555555556vw;
					font-size: 4.444444444vw;
					line-height: 4.444444444vw;
				}

				&2 {
					top: 523px;

					@media (max-width: 1440.5px) {
						top: 36.319444444vw;
					}
				}

				.innerWrapper {
					max-width: 1440px;
					width: 100%;
					height: 100%;
					margin-left: auto;
					margin-right: auto;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					position: relative;
					z-index: 20;

					.line {
						width: 15px;
						height: 100%;
						background-color: white;
						margin-right: 35px;

						@media (max-width: 1440.5px) {
							width: 1.041666667vw;
							margin-right: 2.430555556vw;
						}
					}
				}
			}

			.teamMembers {
				width: 930px;
				max-width: 930px;
				min-width: 930px;
				min-height: 575px;
				margin-top: 50px;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: flex-start;
				position: relative;
				z-index: 20;
				gap: 20px;

				@media (max-width: 1440.5px) {
					width: 64.583333333vw;
					min-width: 64.583333333vw;
					max-width: 64.583333333vw;
					min-height: 39.930555556vw;
					margin-top: 3.472222222vw;
					gap: 1.388888889vw;
				}
			}
		}

		.sectionTen {
			position: relative;
			margin-top: 162px;
			height: max-content;
			padding-top: 130px;

			@media (max-width: 1440.5px) {
				margin-top: 11.25vw;
				padding-top: 9.027777778vw;
			}

			.label {
				width: 100vw;
				height: 80px;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				font-size: 64px;
				line-height: 64px;
				letter-spacing: 0em;

				@media (max-width: 1440.5px) {
					height: 5.555555556vw;
					font-size: 4.444444444vw;
					line-height: 4.444444444vw;
				}

				&2 {
					top: 523px;

					@media (max-width: 1440.5px) {
						top: 36.319444444vw;
					}
				}

				.innerWrapper {
					max-width: 1440px;
					width: 100%;
					height: 100%;
					margin-left: auto;
					margin-right: auto;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.line {
						width: 15px;
						height: 100%;
						background-color: white;
						margin-right: 35px;

						@media (max-width: 1440.5px) {
							width: 1.041666667vw;
							margin-right: 2.430555556vw;
						}
					}
				}
			}

			.text {
				width: 684px;
				margin-left: 50px;
				font-family: "Space Grotesk", sans-serif;
				font-size: 24px;
				font-weight: 300;
				line-height: 31px;
				letter-spacing: 0em;
				text-align: justified;

				@media (max-width: 1440.5px) {
					width: 47.5vw;
					margin-left: 3.472222222vw;
					line-height: 2.152777778vw;
					font-size: 1.666666667vw;
					line-height: 2.152777778vw;
				}
			}

			.infos {
				display: flex;
				flex-direction: column;
				margin-left: 50px;
				margin-top: 33px;

				@media (max-width: 1440.5px) {
					margin-left: 3.472222222vw;
					margin-top: 2.291666667vw;
				}

				.mt {
					margin-top: 57px !important;

					@media (max-width: 1440.5px) {
						margin-top: 3.958333333vw;
					}
				}

				.row {
					display: flex;

					.info {
						width: 262.5px;
						height: 57px;

						@media (max-width: 1440.5px) {
							width: 18.229166667vw;
							height: 3.958333333vw;
						}

						.infoLabel {
							font-family: "Space Grotesk", sans-serif;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							color: #bebebe;

							@media (max-width: 1440.5px) {
								font-size: 1.111111111vw;
								line-height: 1.388888889vw;
							}
						}

						.value {
							font-style: normal;
							font-weight: 600;
							font-size: 30px;
							line-height: 30px;
							text-transform: capitalize;
							color: #ffffff;
							margin-top: 7px;

							@media (max-width: 1440.5px) {
								font-size: 2.083333333vw;
								line-height: 2.083333333vw;
								margin-top: 0.486111111vw;
							}
						}
					}
				}
			}

			.scheduleImgContainer {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				.scheduleImg {
					width: 1287px;
					height: 630px;
					display: block;
					margin-top: 50px;
					margin-left: 50px;

					@media (max-width: 1440.5px) {
						width: 89.375vw;
						height: 43.75vw;
						margin-left: 3.472222222vw;
					}
				}
			}
		}
	}

	.landingWrapperMobile {
		width: 100%;
		overflow: hidden;

		@media (min-width: 768.5px) {
			display: none;
		}

		section {
			// overflow: hidden;
		}

		.s1 {
			width: 295px;
			margin-top: 140px;
			margin-left: auto;
			margin-right: auto;
			position: relative;

			.watermark {
				width: 100vw;
				height: 150px;
				// border: 5px solid red;
				position: absolute;
				top: -150px;
				left: 50%;
				transform: translateX(-50%);
				background: linear-gradient(180deg, rgba(62, 254, 195, 0.4) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
				padding-top: 110px;
				display: flex;
				justify-content: center;
			}

			img {
				max-width: 100%;
				height: auto;
			}

			.textBox {
				width: 295px;
				height: max-content;
				text-align: center;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 10px 0;

				.borderLeft,
				.borderRight {
					width: 25px;
					height: 100%;
					position: absolute;
					transform: translateY(-50%);
					top: 50%;
				}

				.borderLeft {
					left: 0;
					border-top: 1px solid white;
					border-left: 1px solid white;
					border-bottom: 1px solid white;
					border-radius: 10px 0 0 10px;
				}

				.borderRight {
					right: 0;
					border-top: 1px solid white;
					border-right: 1px solid white;
					border-bottom: 1px solid white;
					border-radius: 0 10px 10px 0;
				}

				img {
					max-width: 80%;
					margin-top: 14px;
				}
			}

			.heroText {
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: center;
				color: #a5a5a5;
				margin-top: 33px;

				.underline {
					font-size: 14px;
					font-weight: 700;
					line-height: 14px;
					letter-spacing: 0em;
					text-align: center;
				}
			}

			.btn1,
			.btn2 {
				width: 270px;
				height: 40px;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				line-height: 14px;
				letter-spacing: 0em;
				cursor: pointer;
				margin-left: auto;
				margin-right: auto;
				text-decoration: none;

				span {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.btn1 {
				margin-top: 35px;
				margin-bottom: 15px;
				background: rgba(255, 255, 255, 0.14);
				border: 1px solid #ffffff;
				color: white;
			}

			.btn2 {
				background: linear-gradient(
					90deg,
					#3dfec4 0%,
					#3efec3 6.25%,
					#42febe 12.5%,
					#48fdb7 18.75%,
					#4ffcae 25%,
					#59fca3 31.25%,
					#64fb96 37.5%,
					#70fa88 43.75%,
					#7cf979 50%,
					#88f76a 56.25%,
					#94f65c 62.5%,
					#9ff54f 68.75%,
					#a9f544 75%,
					#b0f43b 81.25%,
					#b6f334 87.5%,
					#baf32f 93.75%,
					#bbf32e 100%
				);

				span {
					color: black;
				}
			}
		}

		.s2 {
			.title {
				width: max-content;
				margin-top: 100px;
				margin-left: auto;
				margin-right: auto;
				text-align: center;

				* {
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					text-transform: uppercase;
				}

				.line1 {
					font-size: 26px;
					line-height: 36px;

					@media (max-width: 450.5px) {
						font-size: 5.777777778vw;
						line-height: 8vw;
					}
				}

				.line2 {
					font-size: 30px;
					line-height: 36px;
					background: linear-gradient(
						90deg,
						#3dfec4 0%,
						#3efec3 6.25%,
						#42febe 12.5%,
						#48fdb7 18.75%,
						#4ffcae 25%,
						#59fca3 31.25%,
						#64fb96 37.5%,
						#70fa88 43.75%,
						#7cf979 50%,
						#88f76a 56.25%,
						#94f65c 62.5%,
						#9ff54f 68.75%,
						#a9f544 75%,
						#b0f43b 81.25%,
						#b6f334 87.5%,
						#baf32f 93.75%,
						#bbf32e 100%
					);
					background-clip: text;
					color: transparent;

					@media (max-width: 450.5px) {
						font-size: 6.666666667vw;
						line-height: 8vw;
					}
				}

				.line3 {
					font-size: 41px;
					line-height: 41px;

					@media (max-width: 450.5px) {
						font-size: 9.111111111vw;
						line-height: 9.111111111vw;
					}
				}
			}

			.personaCardContainer {
				width: max-content;
				min-height: 275px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 64px;
				position: relative;

				.interestsBelt {
					width: 100vw;
					height: 22px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;
					background-color: black;
					border-top: 1px solid white;
					border-bottom: 1px solid white;
					min-width: max-content;
					display: flex;
					justify-content: center;
					align-items: center;

					* {
						font-family: "Space Grotesk" !important;
						font-size: 10px;
						font-weight: 300;
						line-height: 13px;
						letter-spacing: 0em;
					}

					span {
						margin-right: 20px;
						width: max-content;
					}
				}

				.personaCard {
					width: 200px;
					height: 275px;
					position: relative;
					z-index: 3;
					margin-left: auto;
					margin-right: auto;

					img {
						max-width: 100%;
						height: auto;
					}
				}

				.lineGradient {
					position: absolute;
					top: 50%;
					width: 13px;
					height: 140px;
					left: -23px;
					background: linear-gradient(
						90deg,
						#3dfec4 0%,
						#3efec3 6.25%,
						#42febe 12.5%,
						#48fdb7 18.75%,
						#4ffcae 25%,
						#59fca3 31.25%,
						#64fb96 37.5%,
						#70fa88 43.75%,
						#7cf979 50%,
						#88f76a 56.25%,
						#94f65c 62.5%,
						#9ff54f 68.75%,
						#a9f544 75%,
						#b0f43b 81.25%,
						#b6f334 87.5%,
						#baf32f 93.75%,
						#bbf32e 100%
					);
				}
			}

			h4 {
				font-size: 24px;
				font-weight: 400;
				line-height: 29px;
				letter-spacing: 0em;
				width: 300px;
				text-align: center;
				margin-top: 30px;
				margin-left: auto;
				margin-right: auto;

				.bold {
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 24px;
					line-height: 29px;
					letter-spacing: 0em;
				}
			}

			.btn {
				width: 280px;
				height: 40px;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				// font-weight: 700;
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0em;
				cursor: pointer;
				margin-top: 19px;
				margin-bottom: 15px;
				background: rgba(255, 255, 255, 0.14);
				border: 1px solid #ffffff;
				color: white;
				margin-left: auto;
				margin-right: auto;

				span {
					width: max-content;
					height: max-content;
					padding-top: 5px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.s3 {
			margin-left: auto;
			margin-right: auto;
			margin-top: 100px;

			.textBox {
				width: 280px;
				height: 95px;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				padding-top: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				max-width: 80vw;

				.borderLeft,
				.borderRight {
					height: 100%;
					width: 25px;
					position: absolute;
					transform: translateY(-50%);
					top: 50%;
				}

				.borderLeft {
					left: -25px;
					border-left: 1px solid white;
					border-top: 1px solid white;
					border-bottom: 1px solid white;
					border-radius: 10px 0 0 10px;
				}

				.borderRight {
					right: -25px;
					border-right: 1px solid white;
					border-top: 1px solid white;
					border-bottom: 1px solid white;
					border-radius: 0 10px 10px 0;
				}

				// .t1 {
				//     font-family: "SATriumph";
				//     font-style: normal;
				//     font-weight: 400;
				//     font-size: 28px;
				//     line-height: 31px;
				//     text-align: right;
				//     background: linear-gradient(90deg, #3DFEC4 0%, #3EFEC3 6.25%, #42FEBE 12.5%, #48FDB7 18.75%, #4FFCAE 25%, #59FCA3 31.25%, #64FB96 37.5%, #70FA88 43.75%, #7CF979 50%, #88F76A 56.25%, #94F65C 62.5%, #9FF54F 68.75%, #A9F544 75%, #B0F43B 81.25%, #B6F334 87.5%, #BAF32F 93.75%, #BBF32E 100%);
				//     -webkit-background-clip: text;
				//     -webkit-text-fill-color: transparent;
				//     background-clip: text;
				//     align-self: center;
				//     width: max-content;
				// }

				img {
					margin-top: 0;
					max-width: 100% !important;
				}

				.t2 {
					width: 270px;
					height: 34px;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					letter-spacing: 0em;
					text-align: right;
					align-self: flex-end;
					color: white;
				}
			}

			img {
				margin-top: 70px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				width: 335px;
			}
		}

		.s4 {
			margin-top: 100px;

			.labelRow {
				width: 100vw;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				height: 40px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.line {
					width: 5px;
					height: 100%;
					background-color: white;
					margin-left: 28px;
					margin-right: 17px;

					@media (max-width: 420px) {
						margin-left: 5px;
						margin-right: 5px;
					}
				}

				h2 {
					height: max-content;
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 28px;
					line-height: 28px;
				}
			}

			.t1 {
				width: max-content;
				margin-top: 25px;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 14px;
				line-height: 14px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				* {
					font-family: "Raleway", sans-serif;
					font-weight: 600;
				}

				.underline {
					margin-left: 7px;
					margin-right: 7px;
					text-decoration: underline;
				}
			}

			.list {
				width: max-content;
				height: max-content;
				margin-top: 27px;
				margin-left: auto;
				margin-right: auto;

				.listItem {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					&:not(:last-child) {
						margin-bottom: 6px;
					}

					.dot {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: linear-gradient(
							90deg,
							#3dfec4 0%,
							#3efec3 6.25%,
							#42febe 12.5%,
							#48fdb7 18.75%,
							#4ffcae 25%,
							#59fca3 31.25%,
							#64fb96 37.5%,
							#70fa88 43.75%,
							#7cf979 50%,
							#88f76a 56.25%,
							#94f65c 62.5%,
							#9ff54f 68.75%,
							#a9f544 75%,
							#b0f43b 81.25%,
							#b6f334 87.5%,
							#baf32f 93.75%,
							#bbf32e 100%
						);
						margin-right: 20px;
					}

					span {
						font-family: "Space Grotesk";
						font-size: 20px;
						font-weight: 400;
						line-height: 26px;
						letter-spacing: 0em;
						text-align: left;
						color: white;
					}
				}
			}

			.proofOfInterestMobile {
				margin-top: 30px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				max-width: 277px;
				height: auto;
				width: 300px;
			}
		}

		.s5 {
			margin-top: 64px;

			.labelRow {
				width: 100vw;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				height: 40px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.line {
					width: 5px;
					height: 100%;
					background-color: white;
					margin-left: 28px;
					margin-right: 17px;

					@media (max-width: 420px) {
						margin-left: 5px;
						margin-right: 5px;
					}
				}

				h2 {
					height: max-content;
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 28px;
					line-height: 28px;
				}
			}

			.textBoxWrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 50px;

				.label {
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 16px;
					line-height: 16px;
					letter-spacing: 0em;
					text-align: left;
					transform: rotate(-90deg);
					margin-right: -5px;
				}

				.textBox {
					position: relative;
					width: 200px;
					height: 60px;
					padding: 10px;
					display: flex;
					justify-content: center;
					align-items: center;

					.underline {
						text-decoration-line: underline;
					}

					.borderLeft,
					.borderRight {
						width: 15px;
						height: 100%;
						position: absolute;
						transform: translateY(-50%);
						top: 50%;
					}

					.borderLeft {
						left: 0;
						border-top: 1px solid white;
						border-left: 1px solid white;
						border-bottom: 1px solid white;
						border-radius: 6px 0 0 6px;
					}

					.borderRight {
						right: 0;
						border-top: 1px solid white;
						border-right: 1px solid white;
						border-bottom: 1px solid white;
						border-radius: 0 6px 6px 0;
					}

					h4 {
						display: flex;
						flex-direction: column;
					}

					span {
						font-family: "Space Grotesk";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 18px;
					}
				}

				.textBox2 {
					width: 260px;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.suggestionExample,
			.notificationsImg {
				margin-top: 25px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}

			.suggestionExample {
				width: 334px;
			}

			.notificationsImg {
				width: 300px;
			}
		}

		.s6 {
			margin-top: 120px;
			margin-left: auto;
			margin-right: auto;
			height: max-content;
			width: max-content;
			position: relative;
			z-index: 3;

			.listItem {
				width: 290px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:not(:last-child) {
					margin-bottom: 22px;
				}

				.index {
					min-width: 55px;
					font-size: 40px;
					font-weight: 400;
					line-height: 48px;
					letter-spacing: 0em;
					text-align: left;
					margin-right: 35px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				span {
					font-family: "Space Grotesk", sans-serif;
					font-size: 16px;
					font-weight: 300;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;

					.bold {
						font-family: "Space Grotesk", sans-serif;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 0em;
						text-align: left;
					}
				}
			}
		}

		.s7 {
			height: max-content;
			width: max-content;
			margin-left: auto;
			margin-right: auto;
			margin-top: 100px;
			position: relative;
			overflow: visible;
			z-index: 4;

			.row {
				width: 301px;
				height: 110px;
				position: relative;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				z-index: 5;

				.borderTop,
				.borderBottom {
					width: 40px;
					height: 6px;
					background-color: white;
					position: absolute;
					left: 0;
					z-index: 5;
				}

				.borderTop {
					top: 0;
				}

				.borderBottom {
					bottom: 0;
				}

				.contentBox {
					width: 230px;
					height: 100%;
					border-top: 1px solid white;
					border-bottom: 1px solid white;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					position: relative;
					z-index: 5;

					.textBox {
						width: 260px;
						height: 100%;
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						flex-direction: column;
						padding-top: 10px;
						padding-bottom: 21px;

						.label {
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							font-size: 28px;
							line-height: 28px;
							letter-spacing: 0em;
							text-align: left;
							position: relative;
							width: 250px;
						}

						.value {
							width: 191px;
							font-family: "Space Grotesk";
							font-size: 10px;
							font-weight: 300;
							line-height: 13px;
							letter-spacing: 0em;
							text-align: left;
						}
					}

					.dot {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						border: 1px solid white;
						margin-right: 13px;
						margin-bottom: 4px;
						position: absolute;
						bottom: 0;
						right: 0;
					}
				}
			}
		}

		.s8 {
			width: 100%;
			margin-top: 90px;
			position: relative;
			z-index: 10;
			overflow: visible;

			.label {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.line {
					width: 5px;
					height: 40px;
					margin-left: 60px;
					margin-right: 10px;
					background-color: white;
				}

				span {
					font-size: 28px;
					line-height: 28px;
					letter-spacing: 0px;
					text-align: left;
				}
			}

			svg {
				width: 100%;
				height: auto;
			}
		}

		.teamMembersMobile {
			margin-top: 50px;
			overflow: visible;

			.labelRow {
				width: 100vw;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				height: 40px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				position: relative;
				z-index: 5;
				overflow: visible;

				.line {
					width: 5px;
					height: 100%;
					background-color: white;
					margin-left: 28px;
					margin-right: 17px;
					position: relative;
					z-index: 5;

					@media (max-width: 420px) {
						margin-left: 5px;
						margin-right: 5px;
					}
				}

				h2 {
					height: max-content;
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 28px;
					line-height: 28px;
					position: relative;
					z-index: 5;

					@media (max-width: 310px) {
						font-size: 25px;
						line-height: 25px;
					}
				}

				.bgGradientMobileContainer {
					width: 30px;
					height: 30px;
					position: absolute;
					top: 300%;
					left: 50%;
					transform: translateX(-50%);
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 1;
					overflow: visible;

					img {
						position: relative;
						z-index: -1;
						// display: none;
					}
				}
			}

			.membersContainer {
				width: 320px;
				min-height: 320px;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: wrap;
				gap: 10px;
				margin-top: 40px;
				margin-bottom: 40px;
				position: relative;
				z-index: 5;

				.member {
					margin-left: auto;
					margin-right: auto;
				}
			}

			.viewMoreBtn {
				background: rgba(255, 255, 255, 0.14);
				border: 1px solid #ffffff;
				box-sizing: border-box;
				border-radius: 20px;
				width: 280px;
				height: 40px;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				position: relative;
				z-index: 5;
			}
		}

		.s9 {
			margin-top: 60px;
			position: relative;
			z-index: 5;

			.labelRow {
				width: 100vw;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				height: 40px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				position: relative;
				z-index: 5;

				.line {
					width: 5px;
					height: 100%;
					background-color: white;
					margin-left: 28px;
					margin-right: 17px;

					@media (max-width: 420px) {
						margin-left: 5px;
						margin-right: 5px;
					}
				}

				h2 {
					height: max-content;
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 28px;
					line-height: 28px;
				}
			}

			p {
				width: 275px;
				margin-top: 30px;
				margin-bottom: 37px;
				margin-left: auto;
				margin-right: auto;
				font-family: "Space Grotesk";
				font-size: 10px;
				font-weight: 300;
				line-height: 13px;
				letter-spacing: 0em;
				text-align: justified;
			}

			.infoBox {
				width: 280px;
				height: 115px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-left: auto;
				margin-right: auto;

				.top,
				.bottom {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 40px;
					width: 100%;

					.left,
					.right {
						flex: 1;
						height: 100%;
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						flex-direction: column;

						.label {
							margin-bottom: 12px;
							font-family: "Space Grotesk";
							font-size: 10px;
							font-weight: 600;
							line-height: 13px;
							letter-spacing: 0em;
							text-align: left;
						}

						.value {
							font-size: 16px;
							font-weight: 700;
							line-height: 16px;
							letter-spacing: 0em;
							text-align: left;
						}
					}
				}

				.top {
					margin-bottom: 34px;
				}
			}

			.relaseScheduleMobile {
				margin-top: 60px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				width: 330px;
				height: auto;
			}
		}
	}
}
