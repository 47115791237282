.navbarWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 99;
	// background: linear-gradient(180deg, rgba(62, 254, 195, 0.4) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);

	@media (max-width: 768.5px) {
		display: none;
	}

	.landingNavbar {
		height: 120px;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: max-content;
		transition: 0.25s ease all;
		position: relative;
		z-index: 99;

		a {
			text-decoration: none;
			display: block;
		}

		@media (max-width: 1500px) {
			padding: 30px 41px 0 41px;
		}

		@media (max-width: 1440.5px) {
			height: 8.333333333vw;
			padding: 2.083333333vw 2.847222222vw 0 2.847222222vw;
		}

		.left {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 99;

			.firstBatchLogo {
				width: auto;
				height: 40px;

				@media (max-width: 1440.5px) {
					height: 2.777777778vw;
				}
			}

			.brandName {
				font-family: "Raleway", sans-serif;
				font-weight: 500;
				font-size: 24px;
				line-height: 24px;
				letter-spacing: -1.600000023841858px;
				text-align: left;
				text-align: left;
				margin-left: 12px;

				@media (max-width: 1440.5px) {
					font-size: 1.666666667vw;
					line-height: 1.666666667vw;
					margin-left: 0.833333333vw;
				}
			}
		}

		.right {
			width: max-content;
			display: flex;
			justify-content: flex-start;
			height: max-content;
			display: flex;
			justify-content: center;
			align-items: center;

			.link {
				font-family: "Space Grotesk";
				height: max-content;
				width: max-content;
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				line-height: 16px;
				font-weight: 600;
				cursor: pointer;

				@media (max-width: 1440.5px) {
					font-size: 1.111111111vw;
					line-height: 1.111111111vw;
				}

				&:not(:last-child) {
					margin-right: 41px;

					@media (max-width: 1440.5px) {
						margin-right: 2.847222222vw;
					}
				}

				&:last-child {
					margin-bottom: 2px;
				}

				svg {
					width: 27px;
					height: 27px;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;

					@media (max-width: 1440.5px) {
						width: 1.875vw;
						height: 1.875vw;
					}
				}
			}
		}
	}
}