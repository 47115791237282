* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Raleway", sans-serif;
	font-style: normal;
	letter-spacing: 0.03em;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #fafafa;
}

img {
	-webkit-user-select: none !important;
	-khtml-user-select: none !important;
	-moz-user-select: none !important;
	-o-user-select: none !important;
	user-select: none !important;
}

body,
html {
	background-color: black;
	height: calc(100vh - calc(100vh - 100%));
}

h1 {
	font-size: 64px;
	font-weight: 300;

	@media (max-width: 1440.5px) {
		font-size: 4.444444444vw;
	}
}

h2 {
	font-size: 36px;

	@media (max-width: 1440.5px) {
		font-size: 2.5vw;
	}
}

p {
	font-size: 24px;

	@media (max-width: 1440.5px) {
		font-size: 1.666666667vw;
	}
}

// https://stackoverflow.com/questions/2460100/remove-the-complete-styling-of-an-html-button-submit
button,
input[type="submit"],
input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
