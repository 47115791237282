.member {
	width: 170px;
	max-width: 170px;
	min-width: 170px;
	min-height: 235px;

	@media (max-width: 1440.5px) {
		width: 11.805555556vw;
		min-width: 11.805555556vw;
		max-width: 11.805555556vw;
		min-height: 16.319444444vw;
	}

	@media (max-width: 768.5px) {
		width: 95px;
		min-width: 95px;
		max-width: 95px;
		height: 150px;
		margin-bottom: 20px;
	}

	.photo {
		width: 170px;
		height: 170px;
		border-radius: 50%;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		@media (max-width: 1440.5px) {
			width: 11.805555556vw;
			height: 11.805555556vw;
		}

		@media (max-width: 768.5px) {
			width: 93px;
			height: 93px;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			max-height: 100%;
			border: 1px solid white;
			border-radius: 50%;
		}
	}

	.name {
		height: 55px;
		font-size: 20px;
		font-family: "Raleway", sans-serif;
		font-weight: 600;
		line-height: 20px;
		border-bottom: 1px solid white;
		margin-top: 14px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 5px;

		@media (max-width: 1440.5px) {
			height: 3.819444444vw;
			font-size: 1.388888889vw;
			line-height: 1.388888889vw;
			margin-top: 0.972222222vw;
		}

		@media (max-width: 768.5px) {
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			font-size: 13px;
			line-height: 13px;
			letter-spacing: 0px;
			text-align: center;
			margin-top: 8px;
			height: 28px;
		}
	}

	.bottom {
		margin-top: 9px;
		width: 100%;
		min-height: 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@media (max-width: 1440.5px) {
			margin-top: 0.625vw;
			min-height: 1.736111111vw;
		}

		@media (max-width: 1440.5px) {
			margin-top: 4px;
			min-height: 15px;
		}

		.tugceErgulTitle {
			max-width: 80% !important;
		}

		.title {
			font-size: 14px;
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			line-height: 14px;
			letter-spacing: 0px;
			text-align: left;
			max-width: 50%;

			@media (max-width: 1440.5px) {
				font-size: 0.972222222vw;
				line-height: 0.972222222vw;
			}

			@media (max-width: 768.5px) {
				font-size: 9px;
				line-height: 9px;
			}
		}

		.socials {
			display: flex;
			justify-content: center;
			align-items: center;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			svg {
				width: 25px;
				height: 25px;
				margin-right: 8px;

				@media (max-width: 1440.5px) {
					width: 1.736111111vw;
					height: 1.736111111vw;
					margin-right: 0.555555556vw;
				}

				@media (max-width: 1440.5px) {
					width: 15px;
					height: 12px;
					margin-right: 3px;
				}
			}
		}
	}
}
