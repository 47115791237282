.personaWrapper {
	overflow: hidden;

	a {
		text-decoration: none;
	}

	.personaWrapperDesktop {
		width: 100vw;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 120px;

		@media (max-width: 1440.5px) {
			padding-top: 8.333333333vw;
		}

		@media (max-width: 768.5px) {
			display: none !important;
		}

		section {
			overflow: visible;
		}

		.sectionOne {
			width: 100%;
			// margin-top: 75px;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			@media (max-width: 1440.5px) {
				// margin-top: 5.208333333vw;
			}

			.twitterAuthBtn {
				position: relative;

				.personaInfoBoxDesktop {
					width: 250px;
					height: 150px;
					// border: 1px solid red;
					position: absolute;
					top: 70px;
					text-align: center;

					span {
						font-size: 18px;
						line-height: 26px;
					}

					@media (max-width: 1440.5px) {
						width: 17.361111111vw;
						height: 10.416666667vw;
						top: 4.861111111vw;

						span {
							font-size: 1.25vw;
							line-height: 1.805555556vw;
						}
					}
				}
			}

			.left {
				width: 500px;
				height: max-content;
				position: relative;
				z-index: 5;

				@media (max-width: 1440.5px) {
					width: 34.722222222vw;
				}

				h1 {
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 80px;
					line-height: 120px;
					text-transform: lowercase;
					background: linear-gradient(
						90deg,
						#3dfec4 0%,
						#3efec3 6.25%,
						#42febe 12.5%,
						#48fdb7 18.75%,
						#4ffcae 25%,
						#59fca3 31.25%,
						#64fb96 37.5%,
						#70fa88 43.75%,
						#7cf979 50%,
						#88f76a 56.25%,
						#94f65c 62.5%,
						#9ff54f 68.75%,
						#a9f544 75%,
						#b0f43b 81.25%,
						#b6f334 87.5%,
						#baf32f 93.75%,
						#bbf32e 100%
					);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;

					@media (max-width: 1440.5px) {
						font-size: 5.555555556vw;
						line-height: 8.333333333vw;
					}
				}

				h2 {
					font-style: normal;
					font-weight: 700;
					font-size: 24px;
					line-height: 24px;
					text-transform: uppercase;
					margin-top: 20px;
					margin-bottom: 80px;

					@media (max-width: 1440.5px) {
						font-size: 1.666666667vw;
						line-height: 1.666666667vw;
						margin-top: 1.388888889vw;
						margin-bottom: 5.555555556vw;
					}
				}

				.step1 {
					margin-bottom: 45px;

					@media (max-width: 1440.5px) {
						margin-bottom: 3.125vw;
					}
				}

				.step2 {
					opacity: 0.4 !important;
				}

				.step1,
				.step2 {
					position: relative;

					.textbox {
						width: 100%;
						min-height: 64px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;

						@media (max-width: 1440.5px) {
							min-height: 4.444444444vw;
						}

						.index {
							width: 80px;
							height: 100%;
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							font-size: 64px;
							line-height: 64px;
							color: white;

							@media (max-width: 1440.5px) {
								width: 5.555555556vw;
								font-size: 4.444444444vw;
								line-height: 4.444444444vw;
							}
						}

						.dot {
							width: 10px;
							height: 10px;
							border-radius: 50%;
							border: 1px solid white;
							margin-left: 45px;
							margin-right: 25px;

							@media (max-width: 1440.5px) {
								width: 0.694444444vw;
								height: 0.694444444vw;
								margin-left: 3.125vw;
								margin-right: 1.736111111vw;
							}
						}

						.text {
							flex: 1;
							height: 100%;
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;
							flex-direction: column;

							.t1 {
								font-family: "Raleway", sans-serif;
								font-weight: 600;
								font-size: 24px;
								line-height: 24px;
								letter-spacing: 0em;
								text-align: left;

								@media (max-width: 1440.5px) {
									font-size: 1.666666667vw;
									line-height: 1.666666667vw;
								}
							}

							.t2 {
								width: 300px;
								font-size: 14px;
								font-weight: 100;
								line-height: 17px;
								margin-top: 10px;

								@media (max-width: 1440.5px) {
									width: 20.833333333vw;
									font-size: 0.972222222vw;
									line-height: 1.180555556vw;
									margin-top: 0.694444444vw;
								}
							}
						}
					}

					.disabled {
						pointer-events: none !important;
					}

					.button {
						width: 200px;
						height: 55px;
						background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
						border: 1px solid rgba(255, 255, 255, 0.8);
						box-sizing: border-box;
						border-radius: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-family: "Raleway", sans-serif;
						font-weight: 600;
						color: black;
						cursor: pointer;
						font-size: 16px;
						line-height: 16px;
						color: white;
						position: relative;
						margin-top: 25px;

						@media (max-width: 1440.5px) {
							width: 13.888888889vw;
							height: 3.819444444vw;
							border-radius: 2.083333333vw;
							font-size: 1.111111111vw;
							line-height: 1.111111111vw;
							margin-top: 1.736111111vw;
						}

						.btnInner {
							display: flex;

							span {
								display: flex;
								justify-content: center;
								align-items: center;
							}

							svg {
								width: 23px;
								height: 19px;
								margin-right: 10px;

								@media (max-width: 1440.5px) {
									width: 1.597222222vw;
									height: 2.013888889vw;
									margin-right: 0.694444444vw;
								}
							}
						}
					}
				}
			}

			.right {
				width: 550px;
				height: 100%;

				@media (max-width: 1440.5px) {
					width: 38.194444444vw;
				}

				.announcementBox {
					width: 100%;
					height: max-content;
					border: 1px solid #8989894d;
					margin-top: 257px;
					padding-top: 38px;
					padding-left: 33px;
					padding-bottom: 38px;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					flex-direction: column;
					border-radius: 30px;
					position: relative;

					@media (max-width: 1440.5px) {
						margin-top: 17.847222222vw;
						padding-top: 2.638888889vw;
						padding-left: 2.291666667vw;
						padding-bottom: 2.638888889vw;
						border-radius: 2.083333333vw;
					}

					.label {
						width: 260px;
						height: 24px;
						position: absolute;
						top: 0;
						left: 43px;
						background-color: black;
						transform: translateY(-50%);
						display: flex;
						justify-content: center;
						align-items: center;

						@media (max-width: 1440.5px) {
							width: 18.055555556vw;
							height: 1.666666667vw;
							left: 2.986111111vw;
						}

						h2 {
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							font-size: 24px;
							line-height: 24px;
							color: #8989894d;

							@media (max-width: 1440.5px) {
								font-size: 1.666666667vw;
								line-height: 1.666666667vw;
							}
						}

						.gradientBox {
							width: 10px;
							height: 10px;
							position: absolute;
							top: 100%;
							left: 50%;
							transform: translate(-50%, -50%);

							@media (max-width: 1440.5px) {
								width: 0.694444444vw;
								height: 0.694444444vw;
							}

							img {
								position: absolute;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								z-index: 1;
							}
						}
					}

					.step3 {
						margin-bottom: 45px;

						@media (max-width: 1440.5px) {
							margin-bottom: 3.125vw;
						}
					}

					.step3,
					.step4 {
						.textbox {
							width: 100%;
							min-height: 64px;
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;

							@media (max-width: 1440.5px) {
								min-height: 4.444444444vw;
							}

							.index {
								width: 80px;
								height: 100%;
								font-family: "Raleway", sans-serif;
								font-weight: 600;
								font-size: 64px;
								line-height: 64px;
								color: #8989894d;

								@media (max-width: 1440.5px) {
									width: 5.555555556vw;
									font-size: 4.444444444vw;
									line-height: 4.444444444vw;
								}
							}

							.dot {
								width: 10px;
								height: 10px;
								border-radius: 50%;
								border: 1px solid #8989894d;
								margin-left: 45px;
								margin-right: 25px;

								@media (max-width: 1440.5px) {
									width: 0.694444444vw;
									height: 0.694444444vw;
									margin-left: 3.125vw;
									margin-right: 1.736111111vw;
								}
							}

							.text {
								flex: 1;
								height: 100%;
								display: flex;
								justify-content: flex-start;
								align-items: flex-start;
								flex-direction: column;

								.t1 {
									font-family: "Raleway", sans-serif;
									font-weight: 600;
									font-size: 24px;
									line-height: 24px;
									letter-spacing: 0em;
									text-align: left;
									color: #8989894d;

									@media (max-width: 1440.5px) {
										font-size: 1.666666667vw;
										line-height: 1.666666667vw;
									}
								}

								.t2 {
									font-size: 14px;
									font-weight: 100;
									line-height: 17px;
									letter-spacing: 0em;
									text-align: left;
									margin-top: 10px;
									color: #8989894d;

									@media (max-width: 1440.5px) {
										font-size: 0.972222222vw;
										line-height: 1.180555556vw;
										margin-top: 0.694444444vw;
									}
								}
							}
						}

						.button {
							width: 200px;
							height: 55px;
							background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
							opacity: 0.3;
							border: 1px solid rgba(255, 255, 255, 0.8);
							box-sizing: border-box;
							border-radius: 30px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							color: black;
							font-size: 16px;
							line-height: 16px;
							color: white;
							position: relative;
							margin-top: 25px;

							@media (max-width: 1440.5px) {
								width: 13.888888889vw;
								height: 3.819444444vw;
								border-radius: 2.083333333vw;
								font-size: 1.111111111vw;
								line-height: 1.111111111vw;
								margin-top: 1.736111111vw;
							}

							svg {
								width: 23px;
								height: 19px;
								margin-right: 30px;

								@media (max-width: 1440.5px) {
									width: 1.597222222vw;
									height: 1.319444444vw;
									margin-right: 2.083333333vw;
								}
							}
						}
					}
				}
			}
		}

		.infoBox {
			height: max-content;
			width: 250px;
			margin-left: 150px;
			margin-right: auto;
			top: 0;
			right: 0;
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
			flex-direction: column;
			padding: 10px 0;
			border-radius: 6px;
			display: none;

			.condition {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 5px 0;

				.status {
					width: 50px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						width: 24px;
						height: 24px;
					}
				}

				.label {
					height: 100%;
					flex: 1;
					font-size: 14px;
					font-weight: 100;
					line-height: 17px;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					@media (max-width: 1440.5px) {
						font-size: 0.972222222vw;
						line-height: 1.180555556v;
					}
				}
			}

			.result {
				width: 100%;
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-weight: 100;
				line-height: 17px;
				margin-top: 10px;
				text-decoration: underline;
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				text-align: center;

				@media (max-width: 1440.5px) {
					font-size: 0.972222222vw;
					line-height: 1.180555556v;
				}
			}
		}

		.sectionTwo {
			width: max-content;
			height: max-content;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			margin-top: 220px;

			@media (max-width: 1440.5px) {
				margin-top: 15.277777778vw;
			}

			.left,
			.right {
				height: max-content;
			}

			.left {
				width: 500px;
				margin-right: 60px;

				@media (max-width: 1440.5px) {
					width: 34.722222222vw;
					margin-right: 4.166666667vw;
				}

				svg {
					max-width: 100%;
					height: auto;
					position: relative;
					z-index: 15;
				}
			}

			.right {
				width: 450px;
				height: max-content;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: column;

				@media (max-width: 1440.5px) {
					width: 31.25vw;
				}

				h3 {
					margin-left: 37px;
					margin-bottom: 50px;
					font-family: "Raleway", sans-serif;
					font-weight: 600;
					font-size: 30px;
					line-height: 30px;
					letter-spacing: 0em;
					text-align: left;
					position: relative;
					z-index: 5;
					margin-top: 50px;

					@media (max-width: 1440.5px) {
						margin-left: 2.569444444vw;
						margin-bottom: 3.472222222vw;
						font-size: 2.083333333vw;
						line-height: 2.083333333vw;
						margin-top: 3.472222222vw;
					}
				}
			}
		}
	}

	.personaWrapperMobile {
		@media (min-width: 768.5px) {
			display: none !important;
		}

		.s1 {
			margin-top: 150px;

			h1 {
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				font-style: normal;
				font-weight: 400;
				font-size: 40px;
				line-height: 48px;
				text-transform: lowercase;
				background: linear-gradient(
					90deg,
					#3dfec4 0%,
					#3efec3 6.25%,
					#42febe 12.5%,
					#48fdb7 18.75%,
					#4ffcae 25%,
					#59fca3 31.25%,
					#64fb96 37.5%,
					#70fa88 43.75%,
					#7cf979 50%,
					#88f76a 56.25%,
					#94f65c 62.5%,
					#9ff54f 68.75%,
					#a9f544 75%,
					#b0f43b 81.25%,
					#b6f334 87.5%,
					#baf32f 93.75%,
					#bbf32e 100%
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				margin-left: auto;
				margin-right: auto;
				width: max-content;
				text-align: center;
			}

			h3 {
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				font-style: normal;
				font-size: 12px;
				line-height: 14px;
				text-align: justify;
				text-transform: uppercase;
				color: #ebebeb;
				width: 230px;
				margin-top: 17px;
				text-align: center;
			}
		}

		.s2 {
			margin-top: 60px;
			min-height: max-content;

			.step2 {
				opacity: 0.4 !important;
			}

			.step1,
			.step2 {
				height: 130px;
				width: 290px;
				margin-left: auto;
				margin-right: auto;

				.textRow {
					height: 32px;
					width: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;

					.index {
						font-size: 32px;
						font-weight: 400;
						line-height: 32px;
					}

					.dot {
						width: 5px;
						height: 5px;
						border-radius: 50%;
						border: 1px solid white;
						margin-bottom: 5px;
						margin-left: 13px;
						margin-right: 10px;
					}

					.text {
						font-family: "Raleway", sans-serif;
						font-weight: 600;
						font-size: 16px;
						line-height: 16px;
						margin-bottom: 3px;
					}
				}

				.disabled {
					pointer-events: none !important;
				}

				.btn {
					width: 100%;
					height: 40px;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
					border: 1px solid rgba(255, 255, 255, 0.8);
					box-sizing: border-box;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: white;
					position: relative;
					margin-top: 12px;
				}

				.info {
					width: 115px;
					height: 30px;
					margin-left: auto;
					margin-top: 10px;
					font-style: normal;
					font-weight: 100;
					font-size: 8px;
					line-height: 10px;
					text-align: justify;
					text-transform: lowercase;
				}
			}

			.infoBox {
				width: 100%;
				height: 50px;
				// border: 1px solid red;
				margin-top: 10px;
				margin-bottom: 20px;
				text-align: center;
				opacity: 1 !important;

				span {
					font-size: 16px;
					line-height: 24px;
					color: white;
					font-family: "Raleway", sans-serif;
					font-weight: 600;
				}
			}

			.toBeAnnounced {
				width: 340px;
				height: 220px;
				border: 1px solid white;
				border-radius: 25px;
				position: relative;
				margin-top: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				opacity: 0.5;
				margin-left: auto;
				margin-right: auto;

				.label {
					width: 160px;
					height: 15px;
					position: absolute;
					left: 43px;
					top: 0;
					transform: translateY(-50%);
					background-color: black;
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					line-height: 14px;
					text-transform: uppercase;
					color: #898989;
					text-align: center;
				}

				.step4 {
					margin-top: 10px;
				}

				.step3,
				.step4 {
					height: 80px;
					width: 290px;
					margin-left: auto;
					margin-right: auto;

					.textRow {
						height: 32px;
						width: 100%;
						display: flex;
						justify-content: flex-start;
						align-items: flex-end;

						.index {
							font-size: 32px;
							font-weight: 400;
							line-height: 32px;
						}

						.dot {
							width: 5px;
							height: 5px;
							border-radius: 50%;
							border: 1px solid white;
							margin-bottom: 5px;
							margin-left: 13px;
							margin-right: 10px;
						}

						.text {
							font-family: "Raleway", sans-serif;
							font-weight: 600;
							font-size: 16px;
							line-height: 16px;
							margin-bottom: 3px;
						}
					}

					.btn {
						width: 100%;
						height: 40px;
						font-weight: 400;
						font-size: 14px;
						line-height: 14px;
						background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
						border: 1px solid rgba(255, 255, 255, 0.8);
						box-sizing: border-box;
						border-radius: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: white;
						position: relative;
						margin-top: 12px;
					}

					.info {
						width: 115px;
						height: 30px;
						margin-left: auto;
						margin-top: 10px;
						font-style: normal;
						font-weight: 100;
						font-size: 8px;
						line-height: 10px;
						text-align: justify;
						text-transform: lowercase;
					}
				}
			}
		}

		.personaImgBox {
			position: relative;

			.personaCard {
				width: 270px;
				height: auto;
				margin-top: 50px;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				z-index: 3;
			}

			.bgLayerMobile {
				width: 400px;
				height: 400px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 0;

				img {
					position: absolute;
					top: 60%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 0;
					width: 800px;
					height: 1000px;
				}
			}
		}

		.s3 {
			width: 300px;
			position: relative;
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	.personaWrapperDesktop,
	.personaWrapperMobile {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		min-height: 100vh;

		.pageContent {
			flex: 1;
		}
	}
}
