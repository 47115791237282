.nftMintingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding-top: 120px;

    @media (max-width: 1440.5px) {
        padding-top: 8.333333333vw;
    }

    @media (max-width: 768.5px) {
        padding-top: 100px;
    }

    .pageContent {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        h1 {
            font-size: 2rem;
            max-width: 80vw;
            text-align: center;
            margin-top: 30px;
            width: max-content;
            margin-left: auto;
            margin-right: auto;
        }

        a {
            font-size: 1.5rem;
            margin-top: 20px;
        }

        .buttons {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                svg {
                    margin-right: 10px;
                }
            }

            button:first-child {
                margin-right: 30px;
            }
        }

        button {
            padding: 5px 20px;
            border: 1px solid white;
            border-radius: 10px;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 40px;
                margin-right: 10px;
            }
        }
    }
}