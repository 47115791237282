.navbarMobile {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 99;
	// background: linear-gradient(180deg, rgba(62, 254, 195, 0.4) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 43px;
	padding-left: 43px;
	// padding-top: 18px;

	@media (min-width: 768.5px) {
		display: none;
	}

	.watermark {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101px;

		svg {
			width: 100% !important;
			height: 100% !important;
			position: relative;
			z-index: 101;
		}
	}

	a {
		text-decoration: none;
		display: block;
	}

	.navRow {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1;
		position: relative;
		z-index: 200;

		.left,
		.right {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.left {
			position: relative;
			z-index: 102 !important;

			.brandName {
				font-size: 24px;
				font-family: "Raleway", sans-serif;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: -3px;
				margin-left: 6px;
			}
		}

		.active {
			path {
				fill: black;
			}
		}

		.right {
			.menuIcon {
				position: relative;
				z-index: 200;
				cursor: pointer;
			}

			path {
				transition: 0.25s ease all;
			}
		}
	}

	.bgLayer {
		width: 100vw;
		height: 100vh;
		display: none;
		top: 0;
		left: 0;
		z-index: -1000 !important;
		transition: 0.2s ease all;
	}

	.bgLayerShow {
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 150;
		display: block;
		position: absolute;
	}

	.show {
		right: 0 !important;
	}

	.offcanvasWrapper {
		height: 100vh;
		width: 160px;
		// background: linear-gradient(90deg, #3DFEC4 0%, #3EFEC3 6.25%, #42FEBE 12.5%, #48FDB7 18.75%, #4FFCAE 25%, #59FCA3 31.25%, #64FB96 37.5%, #70FA88 43.75%, #7CF979 50%, #88F76A 56.25%, #94F65C 62.5%, #9FF54F 68.75%, #A9F544 75%, #B0F43B 81.25%, #B6F334 87.5%, #BAF32F 93.75%, #BBF32E 100%);
		background-color: black;
		position: fixed;
		top: 0;
		right: -260px;
		transition: 0.25s ease all;
		z-index: 170;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding-top: 120px;
		background: linear-gradient(180deg, #59fca3 0%, #000000 47.6%);

		* {
			color: black;
		}

		.links {
			.link {
				width: max-content;
				height: max-content;
				font-family: "Space Grotesk";
				font-size: 18px;
				font-weight: 500;
				line-height: 23px;
				letter-spacing: 0.4000000059604645px;
				text-align: left;
				cursor: pointer;
				margin-left: auto;
				margin-right: auto;

				&:not(:last-child) {
					margin-bottom: 17px;
				}
			}
		}

		.socials {
			margin-top: 77px;
			display: flex;
			justify-content: center;
			align-items: center;

			a {
				display: flex;
				justify-content: center;
				align-items: center;

				&:not(:last-child) {
					margin-right: 11px;
				}
			}

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}
}
