.footerDesktop {
	height: 250px;
	max-width: 1440px;
	margin-top: 100px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	// padding: 38px 250px;
	overflow: hidden;
	border-top: 1px solid white;
	padding: 50px;

	@media (max-width: 1440.5px) {
		height: 17.361111111vw;
		margin-top: 6.944444444vw;
		padding: 3.472222222vw;
	}

	@media (max-width: 768.5px) {
		padding: 30px 30px !important;
		height: 125px;
	}

	@media (max-width: 450px) {
		height: 90px;
	}

	a {
		text-decoration: none;
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.left,
	.center,
	.right {
		flex: 1;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		position: relative;
		z-index: 15;
	}

	.left {
		font-size: 18px;
		font-family: "Raleway", sans-serif;
		font-weight: 600;
		line-height: 18px;
		letter-spacing: 0.4000000059604645px;
		text-align: left;

		@media (max-width: 1440.5px) {
			font-size: 1.25vw;
			line-height: 1.25vw;
			letter-spacing: 0.027777778vw;
		}

		@media (max-width: 768.5px) {
			font-size: 9px;
			line-height: 9px;
			letter-spacing: 0.4000000059604645px;
		}

		.link {
			cursor: pointer;

			&:not(:last-child) {
				margin-bottom: 7px;

				@media (max-width: 1440.5px) {
					margin-bottom: 0.486111111vw;
				}
			}
		}

		.copyright {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 15px;

			@media (max-width: 1440.5px) {
				margin-top: 1.041666667vw;
			}

			svg {
				width: 20px;
				height: 20px;
				margin-right: 4px;

				@media (max-width: 1440.5px) {
					width: 1.388888889vw;
					height: 1.388888889vw;
					margin-right: 0.277777778vw;
				}

				@media (max-width: 768.5px) {
					width: 8px;
					height: 8px;
				}
			}
		}
	}

	.center {
		align-items: center;
		font-size: 48px;
		font-family: "Raleway", sans-serif;
		font-weight: 600;
		line-height: 48px;
		text-align: left;

		a {
			letter-spacing: -4px;
		}

		@media (max-width: 1440.5px) {
			font-size: 3.333333333vw;
			line-height: 3.333333333vw;

			a {
				letter-spacing: -0.277777778vw;
			}
		}
	}

	.right {
		align-items: flex-end;

		.contact {
			font-size: 18px;
			font-family: "Raleway", sans-serif;
			font-weight: 600;
			line-height: 18px;
			letter-spacing: 0em;

			@media (max-width: 1440.5px) {
				font-size: 1.25vw;
				line-height: 1.25vw;
			}

			@media (max-width: 768.5px) {
				font-size: 9px;
				line-height: 9px;
				letter-spacing: 0.4000000059604645px;
			}
		}

		.mail {
			font-family: "Space Grotesk";
			font-size: 18px;
			font-weight: 500;
			line-height: 23px;
			letter-spacing: 0.4000000059604645px;
			margin-top: 5px;
			margin-bottom: 11px;

			@media (max-width: 1440.5px) {
				font-size: 1.25vw;
				line-height: 1.597222222vw;
				letter-spacing: 0.027777778vw;
				margin-top: 0.347222222vw;
				margin-bottom: 0.763888889vw;
			}

			@media (max-width: 768.5px) {
				font-size: 7px;
				line-height: 7px;
				letter-spacing: 0.4000000059604645px;
			}
		}

		.socials {
			display: flex;

			svg {
				width: 19px;
				height: 19px;
				cursor: pointer;

				@media (max-width: 1440.5px) {
					width: 1.319444444vw;
					height: 1.319444444vw;
				}

				@media (max-width: 768.5px) {
					width: 12px;
					height: 12px;
				}
			}

			a {
				&:not(:last-child) {
					margin-right: 28px;

					@media (max-width: 1440.5px) {
						margin-right: 1.944444444vw;
					}

					@media (max-width: 768.5px) {
						margin-right: 5px;
					}
				}
			}
		}
	}

	.footerBg {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
		width: 100%;
		height: auto;
	}
}
