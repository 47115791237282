.personaAnalysisWrapper {
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding-top: 120px;

	@media (max-width: 1440.5px) {
		padding-top: 8.333333333vw;
	}

	@media (max-width: 768.5px) {
		padding-top: 100px;
	}

	.pageContent {
		min-height: max-content;
		width: 100%;

		.loginWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			position: relative;
			min-height: 80vh;

			.gradient {
				position: absolute;
				width: 10px;
				height: 10px;
				z-index: 2;
				pointer-events: none;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					pointer-events: none;
					max-height: 200vh;

					@media (max-width: 1440.5px) {
						max-height: 100vh;
					}
				}
			}

			form {
				display: flex;
				flex-direction: column;
				position: relative;
				z-index: 3;

				h1 {
					font-style: normal;
					font-weight: 600;
					font-size: 45px;
					line-height: 45px;
					text-transform: lowercase;
					background: linear-gradient(90deg, #3dfec4 0%, #3efec3 6.25%, #42febe 12.5%, #48fdb7 18.75%, #4ffcae 25%, #59fca3 31.25%, #64fb96 37.5%, #70fa88 43.75%, #7cf979 50%, #88f76a 56.25%, #94f65c 62.5%, #9ff54f 68.75%, #a9f544 75%, #b0f43b 81.25%, #b6f334 87.5%, #baf32f 93.75%, #bbf32e 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-align: center;

					@media (max-width: 1440.5px) {
						font-size: 3.125vw;
						line-height: 3.125vw;
					}

					@media (max-width: 768.5px) {
						font-size: 32px;
						line-height: 32px;
					}
				}

				h2 {
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 18px;
					letter-spacing: 0.03em;
					text-transform: uppercase;
					color: #ebebeb;
					margin-top: 18px;
					margin-bottom: 40px;
					width: 440px;
					text-align: center;

					@media (max-width: 1440.5px) {
						font-size: 1.25vw;
						line-height: 1.25vw;
						margin-top: 1.25vw;
						margin-bottom: 2.777777778vw;
						width: 30.555555556vw;
					}

					@media (max-width: 768.5px) {
						font-size: 14px;
						line-height: 14px;
						margin-top: 18px;
						margin-bottom: 10px;
						width: 90vw;
						max-width: 400px;
					}
				}

				input {
					width: 100%;
					height: 50px;
					background: transparent;
					outline: none;
					box-shadow: none;
					border: 1px solid red;
					border-radius: 6px;
					background: rgba(196, 196, 196, 0.1);
					border: 1px solid rgba(255, 255, 255, 0.8);
					font-size: 16px;
					line-height: 16px;
					font-weight: 600;
					padding: 0 24px;
					margin-top: 18px;
					margin-bottom: 100px;

					@media (max-width: 1440.5px) {
						height: 3.472222222vw;
						border-radius: 0.416666667vw;
						font-size: 1.111111111vw;
						line-height: 1.111111111vw;
						margin-top: 1.25vw;
						margin-bottom: 6.944444444vw;
					}

					@media (max-width: 768.5px) {
						height: 40px;
						border-radius: 6px;
						font-size: 16px;
						line-height: 16px;
						width: 70%;
						margin-left: auto;
						margin-right: auto;
						margin-top: 18px;
						margin-bottom: 48px;
					}
				}

				button {
					width: 200px;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
					border: 1px solid rgba(255, 255, 255, 0.8);
					border-radius: 30px;
					margin-left: auto;
					font-weight: 600;
					margin-right: auto;

					@media (max-width: 1440.5px) {
						width: 13.888888889vw;
						height: 3.472222222vw;
						border-radius: 2.083333333vw;
					}

					@media (max-width: 768.5px) {
						width: 200px;
						height: 50px;
						border-radius: 30px;
						width: 170px;
						height: 40px;
					}
				}
			}
		}

		.resultsWrapper {
			flex: 1;
			min-height: 80vh;

			.section1 {
				width: 100%;
				max-width: 1440px;
				margin-left: auto;
				margin-right: auto;
				height: max-content;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				margin-top: 25px;
				position: relative;

				@media (max-width: 1440.5px) {
					margin-top: 1.736111111vw;
				}

				@media (max-width: 768.5px) {
					margin-top: 25px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}

				.gradient {
					width: 50px;
					height: 50px;
					// border: 1px solid red;
					position: absolute;
					z-index: 2;
					transform: translate(-50%, -50%);
					left: 50%;
					top: 50%;

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-22%, -70%);
						max-height: 300vh;
					}
				}

				.left,
				.right {
					flex: 1;
					height: 100%;
					// border: 1px solid red;
					// background-color: green;
					position: relative;
					z-index: 3;
					display: flex;
					flex-direction: column;
					justify-content: center;
					max-width: max-content !important;
				}

				.left {
					align-items: flex-start;

					@media (max-width: 768.5px) {
						align-items: center;
						position: relative;
						z-index: 85;
					}

					h1 {
						text-transform: lowercase;
						background: linear-gradient(90deg, #3DFEC4 0%, #3EFEC3 6.25%, #42FEBE 12.5%, #48FDB7 18.75%, #4FFCAE 25%, #59FCA3 31.25%, #64FB96 37.5%, #70FA88 43.75%, #7CF979 50%, #88F76A 56.25%, #94F65C 62.5%, #9FF54F 68.75%, #A9F544 75%, #B0F43B 81.25%, #B6F334 87.5%, #BAF32F 93.75%, #BBF32E 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
						font-size: 80px;
						line-height: 80px;
						font-weight: 600;
						margin-top: 40px;

						@media (max-width: 1440.5px) {
							font-size: 5.555555556vw;
							line-height: 5.555555556vw;
							margin-top: 2.777777778vw;
						}

						@media (max-width: 768.5px) {
							font-size: 32px;
							line-height: 32px;
						}
					}

					h2 {
						font-weight: 600;
						font-size: 24px;
						line-height: 24px;
						max-width: 550px;
						margin: 33px 0;
						letter-spacing: 3%;
						letter-spacing: 0.03em;
						text-transform: uppercase;
						color: white;
						text-align: left;

						@media (max-width: 1440.5px) {
							font-size: 1.666666667vw;
							line-height: 1.666666667vw;
							max-width: 38.194444444vw;
							margin: 2.291666667vw 0;
						}

						@media (max-width: 768.5px) {
							font-size: 16px;
							line-height: 21px;
							max-width: 560px;
							margin: 33px 20px;
							text-align: center;
							max-width: 400px;
						}

						@media (max-width: 450px) {
							max-width: 90vw;
						}
					}

					.dropdownBtn {
						position: relative;
						z-index: 99;

						.dropdown {
							min-width: 200px;
							height: max-content;
							max-height: 300px;
							overflow-y: auto;
							border: 1px solid #D6D5D5;
							border-radius: 30px;
							position: absolute;
							transform: translateX(-50%);
							left: 50%;
							margin-top: 10px;
							display: none;
							transition: 2s ease all;
							justify-content: flex-start;
							align-items: center;
							flex-direction: column;
							font-size: 16px;
							line-height: 16px;
							padding: 15px 0;
							backdrop-filter: blur(10px);
							-webkit-backdrop-filter: blur(10px);

							&::-webkit-scrollbar {
								width: 1px;
								height: 10px;
								display: none;
							}

							.user {
								padding: 10px 30px;
								transition: .25s ease all;
								border: 1px solid transparent;
								cursor: pointer;
								width: 100%;

								&:hover {
									border-top: 1px solid #D6D5D5;
									border-bottom: 1px solid #D6D5D5;
									background-color: #3a3a3a66;

									span {
										background: linear-gradient(90deg, rgba(47, 245, 202, 0.5) 0%, rgba(48, 243, 202, 0.5) 6.25%, rgba(49, 238, 202, 0.5) 12.5%, rgba(52, 230, 203, 0.5) 18.75%, rgba(56, 219, 204, 0.5) 25%, rgba(60, 206, 205, 0.5) 31.25%, rgba(65, 190, 206, 0.5) 37.5%, rgba(71, 174, 207, 0.5) 43.75%, rgba(77, 157, 209, 0.5) 50%, rgba(82, 139, 210, 0.5) 56.25%, rgba(88, 123, 211, 0.5) 62.5%, rgba(93, 107, 212, 0.5) 68.75%, rgba(97, 94, 213, 0.5) 75%, rgba(101, 83, 214, 0.5) 81.25%, rgba(104, 75, 215, 0.5) 87.5%, rgba(105, 70, 215, 0.5) 93.75%, rgba(106, 68, 215, 0.5) 100%), #FFFFFF;
										-webkit-background-clip: text;
										-webkit-text-fill-color: transparent;
										background-clip: text;
										text-fill-color: transparent;
									}
								}
							}
						}

						.active {
							display: flex !important;
						}
					}

					button {
						width: 200px;
						height: 50px;
						border-radius: 30px;
						background: linear-gradient(93.11deg, rgba(168, 228, 228, 0.2) 2.85%, rgba(223, 255, 255, 0.2) 103.16%);
						border: 1px solid rgba(255, 255, 255, 0.8);
						display: flex;
						justify-content: center;
						align-items: center;

						.btnInner {
							display: flex;
							justify-content: center;
							align-items: center;
						}

						svg {
							margin-right: 7.5px;
						}

						span {
							font-weight: 600;
							font-size: 18px;
							line-height: 18px;
						}

						@media (max-width: 1440.5px) {
							width: 13.888888889vw;
							height: 3.472222222vw;
							border-radius: 2.083333333vw;

							svg {
								margin-right: 0.520833333vw;
							}

							span {
								font-size: 1.25vw;
								line-height: 1.25vw;
							}
						}

						@media (max-width: 768.5px) {
							width: 180px;
							height: 50px;
							border-radius: 30px;

							svg {
								margin-right: 7.5px;
								font-size: 1.5rem;
							}

							span {
								font-size: 16px;
								line-height: 16px;
							}
						}
					}
				}

				.right {
					align-items: flex-start;

					img {
						max-width: 475px;
						height: auto;

						@media (max-width: 1440.5px) {
							max-width: 32.986111111vw;
						}

						@media (max-width: 768.5px) {
							max-width: 275px;
							margin-top: 50px;
						}
					}
				}
			}

			.resultBox1 {
				margin-top: 220px;
				margin-bottom: 100px;

				@media (max-width: 1440.5px) {
					margin-top: 15.277777778vw;
					margin-bottom: 6.944444444vw;
				}

				@media (max-width: 768.5px) {
					margin-top: 164px;
					margin-bottom: 66px;
				}

				.textContainer {
					.title {
						background: linear-gradient(180deg, #FFB800 0%, #FF4370 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}
				}
			}

			.resultBox2 {
				.textContainer {
					.title {
						background: linear-gradient(90deg, #3DFEC4 0%, #3EFEC3 6.25%, #42FEBE 12.5%, #48FDB7 18.75%, #4FFCAE 25%, #59FCA3 31.25%, #64FB96 37.5%, #70FA88 43.75%, #7CF979 50%, #88F76A 56.25%, #94F65C 62.5%, #9FF54F 68.75%, #A9F544 75%, #B0F43B 81.25%, #B6F334 87.5%, #BAF32F 93.75%, #BBF32E 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}
				}
			}

			.resultBox1,
			.resultBox2 {
				width: 100%;
				max-width: 1440px;
				height: max-content;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				justify-content: center;
				align-items: center;

				@media (max-width: 768.5px) {
					flex-direction: column-reverse;

					.textContainer {
						margin-left: 0 !important;
						padding-left: 0.794444444vw;
						margin-bottom: 7px;

						.subtitle {
							display: none;
						}
					}
				}

				.card {
					width: 550px;
					height: max-content;
					border: 1px solid #c4c4c4;
					border-radius: 30px;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					padding: 60px 30px;

					@media (max-width: 1440.5px) {
						width: 38.194444444vw;
						border-radius: 2.083333333vw;
						padding: 4.166666667vw 2.083333333vw;
					}

					@media (max-width: 768.5px) {
						width: 350px;
						padding: 40px 20px;
						max-width: 95vw;
					}

					.label {
						width: 90%;
						position: absolute;
						transform: translate(-50%, -50%);
						top: 0;
						left: 50%;
						display: flex;
						justify-content: center;
						align-items: center;

						span {
							padding: 10px;
							background-color: black;
							font-size: 24px;
							line-height: 24px;
							font-weight: 600;
							color: white;

							@media (max-width: 1440.5px) {
								font-size: 1.666666667vw;
								line-height: 1.666666667vw;
								padding: 0.694444444vw;
							}

							@media (max-width: 768.5px) {
								font-size: 16px;
								line-height: 16px;
							}
						}

						.gradient {
							flex: 1;
							height: 10px;
							background: linear-gradient(90deg, #2FF5CA 0%, #30F3CA 6.25%, #31EECA 12.5%, #34E6CB 18.75%, #38DBCC 25%, #3CCECD 31.25%, #41BECE 37.5%, #47AECF 43.75%, #4D9DD1 50%, #528BD2 56.25%, #587BD3 62.5%, #5D6BD4 68.75%, #615ED5 75%, #6553D6 81.25%, #684BD7 87.5%, #6946D7 93.75%, #6A44D7 100%);
							border-radius: 10px;

							@media (max-width: 1440.5px) {
								height: 0.694444444vw;
								border-radius: 0.694444444vw;
							}

							@media (max-width: 768.5px) {}
						}
					}

					.interest {
						font-weight: 600;
						font-size: 24px;
						line-height: 24px;
						color: white;

						&:not(:last-child) {
							margin-bottom: 40px;
						}

						@media (max-width: 1440.5px) {
							font-size: 1.666666667vw;
							line-height: 1.666666667vw;

							&:not(:last-child) {
								margin-bottom: 2.777777778vw;
							}
						}

						@media (max-width: 768.5px) {
							font-size: 16px;
							line-height: 16px;

							&:not(:last-child) {
								margin-bottom: 20px;
							}
						}
					}
				}

				.textContainer {
					width: 500px;
					height: max-content;
					margin-left: 100px;

					.title {
						font-size: 30px;
						line-height: 30px;
						font-weight: 600;
						margin-bottom: 24px;
						text-transform: uppercase;
					}

					.subtitle {
						font-size: 24px;
						line-height: 24px;
						font-weight: 600;
						letter-spacing: 3%;
						text-transform: uppercase;
					}

					@media (max-width: 1440.5px) {
						width: 34.722222222vw;
						margin-left: 6.944444444vw;

						.title {
							font-size: 2.083333333vw;
							line-height: 2.083333333vw;
							margin-bottom: 1.666666667vw;
						}

						.subtitle {
							font-size: 1.666666667vw;
							line-height: 1.666666667vw;
							letter-spacing: 3%;
						}
					}

					@media (max-width: 768.5px) {
						width: 350px;
						max-width: 95vw;

						.title {
							font-size: 16px;
							line-height: 16px;
							margin-left: 0;
							margin-bottom: 10px;
						}
					}
				}
			}

			.resultBox3 {
				width: 100%;
				max-width: 1440px;
				height: max-content;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				margin: 250px auto;

				@media (max-width: 1440.5px) {
					margin: 17.361111111vw auto;
				}

				@media (max-width: 768.5px) {
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: 86px auto;
				}

				.left {
					.title {
						font-size: 24px;
						line-height: 24px;
						font-weight: 600;
						width: 315px;
						text-transform: uppercase;
						margin-bottom: 55px;

						@media (max-width: 1440.5px) {
							font-size: 1.666666667vw;
							line-height: 1.666666667vw;
							width: 21.875vw;
							margin-bottom: 3.819444444vw;
						}

						@media (max-width: 768.5px) {
							font-size: 20px;
							line-height: 20px;
							margin-bottom: 25px;
							width: 272px;
							text-align: center;
							max-width: 95vw;
						}
					}

					.selectionCard {
						width: 270px;
						min-height: 300px;
						border: 1px solid #c4c4c4;
						border-radius: 30px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						flex-direction: column;
						padding: 30px 0;

						@media (max-width: 1440.5px) {
							width: 18.75vw;
							min-height: 20.833333333vw;
							border-radius: 2.083333333vw;
							padding: 2.083333333vw 0;
						}

						@media (max-width: 768.5px) {
							width: 100%;
							margin-bottom: 86px;
							padding: 25px 0;
						}

						.cardTitle {
							font-size: 20px;
							line-height: 20px;
							font-weight: 600;
							color: #D6D5D5;
							margin-bottom: 20px;
							padding-left: 40px;

							@media (max-width: 1440.5px) {
								font-size: 1.388888889vw;
								line-height: 1.388888889vw;
								margin-bottom: 1.388888889vw;
								padding-left: 2.777777778vw;
							}

							@media (max-width: 768.5px) {
								font-size: 20px;
								line-height: 20px;
								padding-left: 47px;
								margin-bottom: 20px;
								display: flex;
								justify-content: center;
								align-items: center;

							}
						}

						.trait {
							width: 100%;
							min-height: 46px;
							display: flex;
							justify-content: flex-start;
							align-items: center;
							padding-left: 40px;
							padding-right: 40px;
							transition: .25s ease all;
							cursor: pointer;
							text-transform: uppercase;

							@media (max-width: 1440.5px) {
								min-height: 3.194444444vw;
								padding-left: 2.777777778vw;
								padding-right: 2.777777778vw;
							}

							@media (max-width: 768.5px) {
								min-height: 46px;
								padding-left: 47px;
								padding-right: 47px;
							}

							&:hover {
								background-color: #272727;

								span {
									background: linear-gradient(90deg, rgba(47, 245, 202, 0.5) 0%, rgba(48, 243, 202, 0.5) 6.25%, rgba(49, 238, 202, 0.5) 12.5%, rgba(52, 230, 203, 0.5) 18.75%, rgba(56, 219, 204, 0.5) 25%, rgba(60, 206, 205, 0.5) 31.25%, rgba(65, 190, 206, 0.5) 37.5%, rgba(71, 174, 207, 0.5) 43.75%, rgba(77, 157, 209, 0.5) 50%, rgba(82, 139, 210, 0.5) 56.25%, rgba(88, 123, 211, 0.5) 62.5%, rgba(93, 107, 212, 0.5) 68.75%, rgba(97, 94, 213, 0.5) 75%, rgba(101, 83, 214, 0.5) 81.25%, rgba(104, 75, 215, 0.5) 87.5%, rgba(105, 70, 215, 0.5) 93.75%, rgba(106, 68, 215, 0.5) 100%), #FFFFFF;
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
									background-clip: text;
									text-fill-color: transparent;
								}
							}


							span {
								width: 100%;
								height: 100%;
								font-size: 16px;
								line-height: 16px;
								font-weight: 600;
								color: #D6D5D5;

								@media (max-width: 1440.5px) {
									font-size: 1.111111111vw;
									line-height: 1.111111111vw;
								}

								@media (max-width: 768.5px) {
									font-size: 16px;
									line-height: 16px;
								}
							}
						}

						.active {
							background-color: #272727;

							span {
								background: linear-gradient(90deg, rgba(47, 245, 202, 0.5) 0%, rgba(48, 243, 202, 0.5) 6.25%, rgba(49, 238, 202, 0.5) 12.5%, rgba(52, 230, 203, 0.5) 18.75%, rgba(56, 219, 204, 0.5) 25%, rgba(60, 206, 205, 0.5) 31.25%, rgba(65, 190, 206, 0.5) 37.5%, rgba(71, 174, 207, 0.5) 43.75%, rgba(77, 157, 209, 0.5) 50%, rgba(82, 139, 210, 0.5) 56.25%, rgba(88, 123, 211, 0.5) 62.5%, rgba(93, 107, 212, 0.5) 68.75%, rgba(97, 94, 213, 0.5) 75%, rgba(101, 83, 214, 0.5) 81.25%, rgba(104, 75, 215, 0.5) 87.5%, rgba(105, 70, 215, 0.5) 93.75%, rgba(106, 68, 215, 0.5) 100%), #FFFFFF;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
								background-clip: text;
								text-fill-color: transparent;
							}
						}
					}
				}

				.right {
					width: 445px;
					height: max-content;
					min-height: 250px;
					margin-left: 82px;
					position: relative;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					flex-direction: column;

					@media (max-width: 1440.5px) {
						width: 30.902777778vw;
						min-height: 17.361111111vw;
						margin-left: 5.694444444vw;
					}

					@media (max-width: 768.5px) {
						width: 290px;
						min-height: 250px;
						margin-left: 0;
						max-width: 85vw;
					}

					.lineContainer {
						// border: 1px solid red;
						position: absolute;
						transform: translateX(-50%);
						bottom: 0;
						left: 0;
						width: 30px;
						height: calc(100% - 40px);
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;

						.lineTitle {
							background-color: black;
							// position: relative;
							transform: rotate(-90deg);
							bottom: 0;
							padding: 10px;
							margin-bottom: 70px;
							font-size: 30px;
							line-height: 30px;
							font-weight: 600;
						}

						.line {
							flex: 1;
							width: 2px;
							background-color: white;
						}

						@media (max-width: 1440.5px) {
							width: 2.083333333;

							.lineTitle {
								padding: 0.694444444vw;
								margin-bottom: 4.861111111vw;
								font-size: 2.083333333vw;
								line-height: 2.083333333vw;
							}
						}

						@media (max-width: 768.5px) {
							width: 10px;

							.lineTitle {
								padding: 10px;
								font-size: 20px;
								line-height: 20px;
							}
						}
					}

					.title {
						font-size: 30px;
						line-height: 30px;
						font-weight: 600;

						@media (max-width: 1440.5px) {
							font-size: 2.083333333vw;
							line-height: 2.083333333vw;
						}

						@media (max-width: 768.5px) {
							font-size: 24px;
							line-height: 24px;
						}
					}

					.orange {
						background: linear-gradient(180deg, #FFB800 0%, #FF4370 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}

					.green {
						background: linear-gradient(90deg, #3DFEC4 0%, #3EFEC3 6.25%, #42FEBE 12.5%, #48FDB7 18.75%, #4FFCAE 25%, #59FCA3 31.25%, #64FB96 37.5%, #70FA88 43.75%, #7CF979 50%, #88F76A 56.25%, #94F65C 62.5%, #9FF54F 68.75%, #A9F544 75%, #B0F43B 81.25%, #B6F334 87.5%, #BAF32F 93.75%, #BBF32E 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}

					.blue {
						background: linear-gradient(90deg, #2FF5CA 0%, #30F3CA 6.25%, #31EECA 12.5%, #34E6CB 18.75%, #38DBCC 25%, #3CCECD 31.25%, #41BECE 37.5%, #47AECF 43.75%, #4D9DD1 50%, #528BD2 56.25%, #587BD3 62.5%, #5D6BD4 68.75%, #615ED5 75%, #6553D6 81.25%, #684BD7 87.5%, #6946D7 93.75%, #6A44D7 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}

					.box {
						margin-left: 40px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						flex-direction: column;
						width: 91%;

						&:nth-child(3) {
							margin-top: 36px;

							@media (max-width: 1440.5px) {
								margin-top: 2.5vw;
							}

							@media (max-width: 768.5px) {
								margin-top: 30px;
							}
						}

						&:not(:last-child) {
							margin-bottom: 100px;
						}

						.t1 {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 30px;
							line-height: 30px;
							margin-bottom: 20px;

							span {
								margin-right: 13px;
							}

							img {
								max-width: 26.42px;
							}

							@media (max-width: 1440.5px) {
								img {
									max-width: 1.834722222vw;
								}
							}

							@media (max-width: 768.5px) {
								img {
									max-width: 15px;
								}
							}
						}

						.interest {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							font-weight: 400;
							font-size: 24px;
							line-height: 29px;
							text-transform: uppercase;
							max-width: 600px;

							&:not(:last-child) {
								margin-bottom: 15px;
							}

							.score {
								margin-left: 17px;
							}
						}

						@media (max-width: 1440.5px) {
							margin-left: 2.777777778vw;

							&:not(:last-child) {
								margin-bottom: 6.944444444vw;
							}

							.t1 {
								font-size: 2.083333333vw;
								line-height: 2.083333333vw;
								margin-bottom: 1.388888889vw;

								span {
									margin-right: 0.902777778vw;
								}
							}

							.interest {
								font-size: 1.666666667vw;
								line-height: 2.013888889vw;
								max-width: 41.666666667vw;

								&:not(:last-child) {
									margin-bottom: 1.041666667vw;
								}

								.score {
									margin-left: 1.180555556vw;
								}
							}
						}

						@media (max-width: 768.5px) {
							margin-left: 30px;

							&:not(:last-child) {
								margin-bottom: 75px;
							}

							.t1 {
								font-size: 20px;
								line-height: 20px;
								margin-bottom: 23px;

								span {
									margin-right: 13px;
								}
							}

							.interest {
								font-size: 14px;
								line-height: 16.6px;
								max-width: 270px;

								&:not(:last-child) {
									margin-bottom: 18px;
								}

								.score {
									margin-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}