.personaTraitV2Expanded {
    min-height: 400px !important;
    height: 50px !important;
    max-height: 50px !important;
    overflow: visible !important;

    @media (max-width: 1440.5px) {
        min-height: 27.777777778vw !important;
        height: 3.472222222vw !important;
        max-height: 3.472222222vw !important;
    }

    @media (max-width: 768.5px) {
        min-height: 350px !important;
        height: 350px !important;
        max-height: 350px !important;
    }

    .top {
        width: calc(100% - 20px) !important;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .bottom {
        opacity: 1 !important;
        bottom: 0 !important;
        // right: 0 !important;

        .traitRow {
            opacity: 1 !important;
        }
    }

    .lineContainer {
        // background-color: white !important;

        .line {
            height: 200px !important;
            width: 1px !important;
        }

        .label {
            animation-name: rtl !important;
            animation-duration: 0.25s !important;
            opacity: 1 !important;
        }
    }
}

.personaTraitV2 {
    width: 100%;
    min-height: 50px;
    height: 50px;
    max-height: 50px;
    position: relative;
    transition: 0.25s ease all;
    overflow: hidden;

    @media (max-width: 1440.5px) {
        min-height: 3.472222222vw;
        height: 3.472222222vw;
        max-height: 3.472222222vw;
    }

    @media (max-width: 768.5px) {
        min-height: 40px;
        height: 40px;
        max-height: 40px;
    }

    &:not(:last-child) {
        margin-bottom: 82px;

        @media (max-width: 1440.5px) {
            margin-bottom: 5.694444444vw;
        }

        @media (max-width: 768.5px) {
            margin-bottom: 50px;
        }
    }

    .accordionContainer {
        width: 100%;
        height: 400px;
        position: absolute;
        top: 0;
        right: 0;

        @media (max-width: 1440.5px) {
            height: 27.777777778vw;
            margin-bottom: 27.777777778vw;
        }

        @media (max-width: 768.5px) {
            height: 350px;
        }

        .top {
            height: 50px;
            width: 100%;
            border: 1px solid white;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Raleway", sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            transition: 0.25s ease all;
            cursor: pointer;
            background-color: transparent;
            z-index: 10;

            @media (max-width: 1440.5px) {
                height: 3.472222222vw;
                width: 100%;
                border-radius: 2.083333333vw;
                font-size: 1.666666667vw;
                line-height: 1.666666667vw;
            }

            @media (max-width: 768.5px) {
                height: 40px;
                width: 100%;
                border-radius: 20px;
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
            }
        }

        .bottom {
            width: calc(100% - 25px);
            height: 280px;
            max-height: 280px;
            position: absolute;
            overflow: auto !important;
            bottom: -20px;
            right: 0;
            opacity: 0;
            transition: 0.25s ease all;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 1 auto;
            z-index: 15;
            overflow: auto;


            .traitRow {
                width: 100%;
                opacity: 0;
                transition: 0.25s ease all;

                &:not(:last-child) {
                    margin-bottom: 23px;

                    @media (max-width: 1440.5px) {
                        margin-bottom: 1.597222222vw;
                    }

                    @media (max-width: 768.5px) {
                        margin-bottom: 28px;
                    }
                }

                .explanation {
                    font-family: "Raleway", sans-serif;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 30px;
                    letter-spacing: 0em;
                    color: #898989;

                    @media (max-width: 1440.5px) {
                        font-size: 2.083333333vw;
                        line-height: 2.083333333vw;
                    }

                    @media (max-width: 768.5px) {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }

                .value {
                    width: 100%;
                    height: 12px;
                    border-radius: 10px;
                    border: 1px solid white;
                    margin-top: 5px;
                    margin-bottom: 10px;

                    @media (max-width: 1440.5px) {
                        height: 0.833333333vw;
                        border-radius: 0.694444444vw;
                        margin-top: 0.347222222vw;
                        margin-bottom: 0.694444444vw;
                    }

                    @media (max-width: 768.5px) {
                        height: 10px;
                        border-radius: 10px;
                        margin-top: 5px;
                        margin-bottom: 10px;
                    }

                    .percentageContainer {
                        width: 70%;
                        height: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg,
                                #2ff5ca 0%,
                                #30f3ca 6.25%,
                                #31eeca 12.5%,
                                #34e6cb 18.75%,
                                #38dbcc 25%,
                                #3ccecd 31.25%,
                                #41bece 37.5%,
                                #47aecf 43.75%,
                                #4d9dd1 50%,
                                #528bd2 56.25%,
                                #587bd3 62.5%,
                                #5d6bd4 68.75%,
                                #615ed5 75%,
                                #6553d6 81.25%,
                                #684bd7 87.5%,
                                #6946d7 93.75%,
                                #6a44d7 100%);
                        transition: 0.25s ease all;

                        @media (max-width: 1440.5px) {
                            height: 0.694444444vw;
                            border-radius: 0.694444444vw;
                        }

                        @media (max-width: 768.5px) {
                            height: 8px;
                        }
                    }
                }

                .label {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 29px;
                    letter-spacing: 0em;
                    color: white;

                    @media (max-width: 1440.5px) {
                        font-size: 1.666666667vw;
                        line-height: 2.013888889vw;
                    }

                    @media (max-width: 768.5px) {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }

            .detailCol {
                flex: 1;
                height: max-content;
                position: relative;

                &:first-child {
                    margin-top: 20px;
                }


                @media (max-width: 768.5px) {
                    width: 95%;
                    padding-top: 50px;
                    // border: 1px solid red;
                    min-height: max-content;

                    &:not(:last-child) {
                        margin-bottom: 50px;
                    }
                }

                .labelContainer {
                    // background-color: #d6d5d5;
                    position: absolute;
                    height: 100%;
                    width: 400px;
                    height: 1px;
                    left: 0;
                    bottom: -0px;
                    transform: rotate(-90deg);
                    transform-origin: top left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .line {
                        flex: 1;
                        background: linear-gradient(90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
                        height: 1px;
                    }

                    @media (max-width: 1440.5px) {
                        width: 27.777777778vw;
                    }

                    @media (max-width: 768.5px) {
                        width: 300px;
                        transform: rotate(0);
                        top: 0 !important;
                        width: 100%;
                    }

                    .label {
                        // border: 4px solid blueviolet;
                        padding: 10px;
                        // background-color: black;
                        font-weight: 600;

                        @media (max-width: 1440.5px) {
                            padding: 0.694444444vw;
                        }

                        @media (max-width: 768.5px) {
                            padding: 10px;
                            // display: none;
                        }
                    }
                }

                .traitLabel {
                    width: 90%;
                    height: max-content;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 16px;
                    line-height: 16px;

                    @media (max-width: 1440.5px) {
                        font-size: 1.666666667vw;
                        line-height: 1.666666667vw;
                    }

                    @media (max-width: 768.5px) {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }

                .match {
                    width: 90%;
                    height: 50px;
                    margin-left: auto;
                    margin-right: auto;
                    height: 12px;
                    border-radius: 10px;
                    border: 1px solid white;
                    margin-top: 10px;
                    margin-bottom: 20px;

                    @media (max-width: 1440.5px) {
                        height: 3.472222222vw;
                        height: 0.833333333vw;
                        margin-top: 0.694444444vw;
                    }

                    @media (max-width: 768.5px) {
                        height: 50px;
                        height: 12px;
                        margin-top: 10px;
                    }

                    .percentageContainer {
                        width: 70%;
                        height: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #2ff5ca 0%, #30f3ca 6.25%, #31eeca 12.5%, #34e6cb 18.75%, #38dbcc 25%, #3ccecd 31.25%, #41bece 37.5%, #47aecf 43.75%, #4d9dd1 50%, #528bd2 56.25%, #587bd3 62.5%, #5d6bd4 68.75%, #615ed5 75%, #6553d6 81.25%, #684bd7 87.5%, #6946d7 93.75%, #6a44d7 100%);
                        transition: 0.25s ease all;

                        @media (max-width: 1440.5px) {
                            height: 0.694444444vw;
                            border-radius: 0.694444444vw;
                        }

                        @media (max-width: 768.5px) {
                            height: 10px;
                            border-radius: 10px;
                        }
                    }
                }
            }

            .testbox {
                height: 100%;
                width: 100%;

                .t2 {
                    width: 50px;
                    height: 100px;
                }
            }
        }

        .lineContainer {
            height: 400px;
            width: 1px;
            position: absolute;
            top: 0;
            left: 0;
            // opacity: 0;
            transition: 0.25s ease all;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            @media (max-width: 1440.5px) {
                height: 27.777777778vw;
            }

            @media (max-width: 768.5px) {
                height: 350px;
            }

            .line {
                height: 0;
                width: 0;
                margin-top: 50px;
                background: linear-gradient(90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%), #FFFFFF;
                height: 1px;
                transition: .25s ease all;
            }

            .label {
                width: 180px;
                height: 30px;
                transform: rotate(-90deg) translateY(-50%);
                transform-origin: top left;
                position: absolute;
                bottom: -30px;
                left: 0;
                font-family: "Raleway", sans-serif;
                font-weight: 600;
                font-size: 30px;
                line-height: 30px;
                letter-spacing: 0em;
                opacity: 0;

                @media (max-width: 1440.5px) {
                    width: 12.5vw;
                    height: 2.083333333vw;
                    bottom: -2.083333333vw;
                    font-size: 2.083333333vw;
                    line-height: 2.083333333vw;
                }

                @media (max-width: 768.5px) {
                    width: 120px;
                    height: 20px;
                    bottom: -30px;
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }
    }
}

@keyframes rtl {
    from {
        left: 30px;
    }

    to {
        left: 0px;
    }
}