.personaMapWrapper {
    padding-top: 120px;

    @media (max-width: 1440.5px) {
        padding-top: 8.333333333vw;
    }

    @media (max-width: 768.5px) {
        padding-top: 100px;
    }

    .pageContent {
        min-height: 70vh;

        .loadingContainer {
            min-height: 70vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .stars {
            width: max-content;
            height: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1440.5px) {
                margin-top: 3.472222222vw;
            }

            @media (max-width: 768.5px) {
                margin-top: 20px;
            }

            .active {
                svg {
                    fill: #FCBA03 !important;
                }
            }

            svg {
                width: 100px;
                height: 100px;
                stroke-width: 1;
                stroke: rgba(250, 250, 250, .7);

                @media (max-width: 1440.5px) {
                    width: 6.944444444vw;
                    height: 6.944444444vw;
                }

                @media (max-width: 768.5px) {
                    width: 50px;
                    height: 50px;
                }

                &:not(:last-child) {
                    margin-right: 25px;

                    @media (max-width: 1440.5px) {
                        margin-right: 1.736111111vw;
                    }

                    @media (max-width: 768.5px) {
                        margin-right: 10px;
                    }
                }
            }
        }

        .cryptoTitle {
            font-size: 40px;
            line-height: 40px;
            margin-top: 10px;
            margin-bottom: 80px;
            color: #fafafa;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            @media (max-width: 1440.5px) {
                font-size: 2.777777778vw;
                line-height: 2.777777778vw;
                margin-top: 0.694444444vw;
                margin-bottom: 5.555555556vw;
            }

            @media (max-width: 768.5px) {
                font-size: 24px;
                line-height: 24px;
                margin-top: 5px;
                margin-bottom: 50px;
            }
        }

        .share1 {
            width: 100%;
            max-width: 1440px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-top: 150px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 150px;
            // padding: 5px;

            @media (max-width: 1440.5px) {
                margin-top: 10.416666667vw;
            }

            @media (max-width: 768.5px) {
                margin-top: 80px;
                margin-bottom: 70px;
            }

            .l1,
            .l2 {
                position: absolute;
                width: 35%;
                height: 1px;
                background-color: white;

                @media (max-width: 768.5px) {
                    width: 20%;
                }
            }

            .l1 {
                left: 0;
                background: linear-gradient(-90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
            }

            .l2 {
                right: 0;
                background: linear-gradient(90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
            }

            .b2,
            a {
                background: linear-gradient(90deg, rgba(47, 245, 202, 0.3) 0%, rgba(48, 243, 202, 0.3) 6.25%, rgba(49, 238, 202, 0.3) 12.5%, rgba(52, 230, 203, 0.3) 18.75%, rgba(56, 219, 204, 0.3) 25%, rgba(60, 206, 205, 0.3) 31.25%, rgba(65, 190, 206, 0.3) 37.5%, rgba(71, 174, 207, 0.3) 43.75%, rgba(77, 157, 209, 0.3) 50%, rgba(82, 139, 210, 0.3) 56.25%, rgba(88, 123, 211, 0.3) 62.5%, rgba(93, 107, 212, 0.3) 68.75%, rgba(97, 94, 213, 0.3) 75%, rgba(101, 83, 214, 0.3) 81.25%, rgba(104, 75, 215, 0.3) 87.5%, rgba(105, 70, 215, 0.3) 93.75%, rgba(106, 68, 215, 0.3) 100%);
                border-radius: 40px;
                border: 1px solid rgba(255, 255, 255, 0.8);
                min-width: 200px;
                min-height: 50px;
                text-align: center;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                padding: 20px 40px;

                @media (max-width: 1440.5px) {
                    border-radius: 2.777777778vw;
                    min-width: 13.888888889vw;
                    min-height: 3.472222222vw;
                    padding: 1.388888889vw 2.777777778vw;
                }

                @media (max-width: 768.5px) {
                    border-radius: 30px;
                    min-width: 200px;
                    min-height: 40px;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 15px 30px;
                }
            }
        }


        .results {
            max-width: 1440px;
            margin-top: 100px;
            margin-left: auto;
            margin-right: auto;
            position: relative;



            @media (max-width: 1440.5px) {
                margin-top: 6.944444444vw;
            }

            @media (max-width: 768.5px) {
                margin-top: 60px;
            }

            .scrollAnchor {
                width: 10px;
                height: 10px;
                position: absolute;
                top: -200px;
            }

            .bottom1 {
                height: 1px;
                margin-bottom: 80px;
                background: linear-gradient(90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
                position: relative;

                @media (max-width: 1440.5px) {
                    margin-bottom: 8.125vw;
                }

                @media (max-width: 768.5px) {
                    margin-bottom: 40px;
                }

                .label {
                    position: absolute;
                    left: 140px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: black;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.4px;
                    padding: 0 10px;

                    @media (max-width: 1440.5px) {
                        left: 9.722222222vw;
                        font-size: 1.666666667vw;
                        line-height: 1.666666667vw;
                        padding: 0 0.694444444vw;
                    }

                    @media (max-width: 768.5px) {
                        left: 50px;
                        font-size: 14px;
                        line-height: 14px;
                        padding: 0 10px;
                    }
                }
            }

            .resultRow {
                &:not(:last-child) {
                    margin-bottom: 80px;

                    @media (max-width: 1440.5px) {
                        margin-bottom: 5.555555556vw;
                    }

                    @media (max-width: 768.5px) {
                        margin-bottom: 40px;
                    }
                }

                .top {
                    .label {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        min-height: 80px;
                        margin-left: 150px;
                        margin-right: 150px;

                        @media (max-width: 1440.5px) {
                            min-height: 5.555555556vw;
                            margin-left: 10.416666667vw;
                            margin-right: 10.416666667vw;
                        }

                        @media (max-width: 768.5px) {
                            min-height: 80px;
                            margin-left: 50px;
                            margin-right: 50px;
                            justify-content: center;
                        }

                        .index {
                            font-family: "SATriumph";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 71px;
                            line-height: 79px;
                            color: black;
                            width: max-content;
                            margin-bottom: 20px;
                            margin-right: 12px;
                            text-shadow: -2px -2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, 2px 2px 0 #ffffff;

                            @media (max-width: 1440.5px) {
                                font-size: 4.930555556vw;
                                line-height: 5.486111111vw;
                                margin-bottom: 1.388888889vw;
                                margin-right: 0.833333333vw;
                            }

                            @media (max-width: 768.5px) {
                                font-size: 30px;
                                line-height: 30px;
                                margin-bottom: 10px;
                                margin-right: 12px;
                            }
                        }

                        .line {
                            width: 245px;
                            height: 1px;
                            background-color: white;
                            margin-right: 20px;

                            @media (max-width: 1440.5px) {
                                width: 17.013888889vw;
                            }

                            @media (max-width: 768.5px) {
                                width: 245px;
                            }
                        }

                        .title {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 40px;
                            color: white;

                            @media (max-width: 1440.5px) {
                                font-size: 2.777777778vw;
                                line-height: 2.777777778vw;
                            }

                            @media (max-width: 768.5px) {
                                font-size: 20px;
                                line-height: 20px;
                            }
                        }
                    }

                    .isAllEmpty {
                        max-width: 500px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                        font-size: 21px;
                        line-height: 25px;

                        @media (max-width: 1440.5px) {
                            max-width: 34.722222222vw;
                            font-size: 1.458333333vw;
                            line-height: 1.736111111vw;
                        }

                        @media (max-width: 768.5px) {
                            max-width: 70vw;
                            font-size: 14px;
                            line-height: 17px;
                            margin-bottom: 60px !important;
                        }
                    }

                    .output {
                        width: 90%;
                        height: 175px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;
                        margin-left: auto;
                        margin-right: auto;

                        @media (max-width: 1440.5px) {
                            height: 12.152777778vw;
                        }

                        @media (max-width: 768.5px) {
                            min-height: 175px;
                            flex-wrap: wrap;
                            gap: 10px;
                        }

                        .faded {
                            opacity: .5 !important;
                        }

                        .result {
                            // min-width: 80px;
                            height: 100px;
                            opacity: 1;
                            position: relative;
                            width: max-content !important;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: column;

                            @media (max-width: 1440.5px) {
                                width: 5.555555556vw;
                                height: 6.944444444;
                            }

                            @media (max-width: 768.5px) {
                                width: 41px;
                                height: 70px;
                            }

                            &:not(:last-child) {
                                margin-right: 30px;

                                @media (max-width: 1440.5px) {
                                    margin-right: 0.694444444vw;
                                }

                                @media (max-width: 768.5px) {
                                    margin-right: 5px;
                                }
                            }

                            img {
                                max-width: 100%;
                            }

                            // .imgS {
                            // 	margin-top: 8px;

                            // 	@media (max-width: 768.5px) {
                            // 		margin-top: 4px;
                            // 	}

                            // 	.formationImg {
                            // 		border: 3px solid #c4c4c4;
                            // 	}
                            // }

                            .sm {
                                width: 60px !important;
                                height: 60px !important;

                                @media (max-width: 1440.5px) {
                                    width: 4.166666667vw !important;
                                    height: 4.166666667vw !important;
                                }

                                @media (max-width: 768.5px) {
                                    width: 30px !important;
                                    height: 30px !important;
                                }
                            }

                            .imgContainer {
                                position: relative;
                                width: 80px;
                                min-height: 80px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                @media (max-width: 1440.5px) {
                                    width: 5.555555556vw;
                                    min-height: 5.555555556vw;
                                }

                                @media (max-width: 768.5px) {
                                    width: 41px;
                                    min-height: 41px;
                                }

                                .circle {
                                    position: relative;
                                    top: 0;
                                    left: 0;
                                    z-index: 2;
                                }

                                .formationImg {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    border-radius: 50%;
                                    z-index: 1;
                                    max-width: 95%;
                                    border: 1px solid #fafafa;
                                }

                                .badge {
                                    position: absolute;
                                    bottom: -20px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    max-width: 26px;
                                    z-index: 3;

                                    @media (max-width: 1440.5px) {
                                        bottom: -1.388888889vw;
                                        max-width: 1.805555556vw;
                                    }

                                    @media (max-width: 768.5px) {
                                        bottom: -10px;
                                        max-width: 26px;
                                        height: 20px;
                                        width: auto;
                                    }
                                }
                            }

                            .v1 {
                                font-family: "Space Grotesk", sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 23px;
                                letter-spacing: 0.4px;
                                font-feature-settings: "pnum"on, "lnum"on;
                                color: #ffffff;
                                text-align: center;
                                margin-top: 30px;
                                margin-bottom: 3px;

                                @media (max-width: 1440.5px) {
                                    font-size: 1.25vw;
                                    line-height: 1.597222222vw;
                                    margin-top: 2.083333333vw;
                                    margin-bottom: 0.208333333vw;
                                }

                                @media (max-width: 768.5px) {
                                    font-size: 12px;
                                    line-height: 12px;
                                    margin-top: 16px;
                                    margin-bottom: 6px;
                                }
                            }

                            .v2 {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 20px;
                                letter-spacing: 0.4px;
                                color: #ffffff;
                                text-align: center;
                                width: max-content;
                                // margin-top: 30px;

                                @media (max-width: 1440.5px) {
                                    font-size: 1.388888889vw;
                                    line-height: 1.388888889vw;
                                    // margin-top: 2.083333333vw;
                                }

                                @media (max-width: 768.5px) {
                                    font-size: 12px;
                                    line-height: 12px;
                                    // margin-top: 16px;
                                }
                            }
                        }
                    }
                }

                .bottom {
                    height: 1px;
                    margin-top: 117px;
                    background: linear-gradient(90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
                    position: relative;

                    @media (max-width: 1440.5px) {
                        margin-top: 8.125vw;
                    }

                    @media (max-width: 768.5px) {
                        margin-top: 40px;
                    }

                    .label {
                        position: absolute;
                        left: 140px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: black;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.4px;
                        padding: 0 10px;

                        @media (max-width: 1440.5px) {
                            left: 9.722222222vw;
                            font-size: 1.666666667vw;
                            line-height: 1.666666667vw;
                            padding: 0 0.694444444vw;
                        }

                        @media (max-width: 768.5px) {
                            left: 50px;
                            font-size: 14px;
                            line-height: 14px;
                            padding: 0 10px;
                        }
                    }
                }

                .share {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-top: 150px;
                    margin-left: auto;
                    margin-right: auto;

                    @media (max-width: 1440.5px) {
                        margin-top: 10.416666667vw;
                    }

                    @media (max-width: 768.5px) {
                        margin-top: 80px;
                        margin-bottom: 70px;
                    }

                    .l1,
                    .l2 {
                        position: absolute;
                        width: 35%;
                        height: 1px;
                        background-color: white;

                        @media (max-width: 768.5px) {
                            width: 20%;
                        }
                    }

                    .l1 {
                        left: 0;
                        background: linear-gradient(-90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
                    }

                    .l2 {
                        right: 0;
                        background: linear-gradient(90deg, rgb(47, 245, 202) 0%, rgb(48, 243, 202) 6.25%, rgb(49, 238, 202) 12.5%, rgb(52, 230, 203) 18.75%, rgb(56, 219, 204) 25%, rgb(60, 206, 205) 31.25%, rgb(65, 190, 206) 37.5%, rgb(71, 174, 207) 43.75%, rgb(77, 157, 209) 50%, rgb(82, 139, 210) 56.25%, rgb(88, 123, 211) 62.5%, rgb(93, 107, 212) 68.75%, rgb(97, 94, 213) 75%, rgb(101, 83, 214) 81.25%, rgb(104, 75, 215) 87.5%, rgb(105, 70, 215) 93.75%, rgb(106, 68, 215) 100%);
                    }

                    .b2,
                    a {
                        background: linear-gradient(90deg, rgba(47, 245, 202, 0.3) 0%, rgba(48, 243, 202, 0.3) 6.25%, rgba(49, 238, 202, 0.3) 12.5%, rgba(52, 230, 203, 0.3) 18.75%, rgba(56, 219, 204, 0.3) 25%, rgba(60, 206, 205, 0.3) 31.25%, rgba(65, 190, 206, 0.3) 37.5%, rgba(71, 174, 207, 0.3) 43.75%, rgba(77, 157, 209, 0.3) 50%, rgba(82, 139, 210, 0.3) 56.25%, rgba(88, 123, 211, 0.3) 62.5%, rgba(93, 107, 212, 0.3) 68.75%, rgba(97, 94, 213, 0.3) 75%, rgba(101, 83, 214, 0.3) 81.25%, rgba(104, 75, 215, 0.3) 87.5%, rgba(105, 70, 215, 0.3) 93.75%, rgba(106, 68, 215, 0.3) 100%);
                        border-radius: 40px;
                        border: 1px solid rgba(255, 255, 255, 0.8);
                        min-width: 200px;
                        min-height: 50px;
                        text-align: center;
                        font-weight: 600;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        padding: 20px 40px;

                        @media (max-width: 1440.5px) {
                            border-radius: 2.777777778vw;
                            min-width: 13.888888889vw;
                            min-height: 3.472222222vw;
                            padding: 1.388888889vw 2.777777778vw;
                        }

                        @media (max-width: 768.5px) {
                            border-radius: 30px;
                            min-width: 200px;
                            min-height: 40px;
                            font-size: 14px;
                            line-height: 14px;
                            padding: 15px 30px;
                        }
                    }
                }
            }
        }
    }
}